import axios, { AxiosError } from 'axios';
import { IError } from '@/interfaces/error';
import useError from '@/store/error';

export const handleError = (err: AxiosError | unknown) => {
  const errorStore = useError();
  const error = (err as AxiosError)?.response as unknown as IError;
  if (error) {
    errorStore.setError(error);
  }
  if (axios.isAxiosError(err)) {
    const data = err?.response?.data;
    return data;
  }
  return {};
};
