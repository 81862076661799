import { defineStore } from 'pinia';
import { ICategory } from '@/interfaces/category';
import { TCategoryResourceTreeHashmap } from '@/types/categoryTypes';
import { IResources } from '@/interfaces/resources';
import { getList } from '@/services/listService';
import { EEndpoint } from '@/enums/api';

const useCategory = defineStore('category', {
  state: () => ({
    activeCategory: {} as ICategory,
    adminItemsMap: {} as TCategoryResourceTreeHashmap,
    categoryItemsMap: {} as TCategoryResourceTreeHashmap,
    adminFlatList: [] as IResources[],
    categoryFlatList: [] as ICategory[],
    resetFilter: false,
  }),
  getters: {
    activeCategoryLabel: (state) => state.activeCategory.label,
    activeCategoryParentId: (state) => state.activeCategory.parent,
    // eslint-disable-next-line max-len
    activeCategoryActiveCategoryGroup: (state) => (langId: number) => state.activeCategory.categoryObjects.find((object) => object.languageId === langId),
    getAdminItemsMap: (state) => state.adminItemsMap,
    getCategoryItemsMap: (state) => state.categoryItemsMap,
    getCategoryById: (state) => (id:number) => state.categoryItemsMap[id],
    getAdminFlatList: (state) => state.adminFlatList,
    getCategoryFlatList: (state) => state.categoryFlatList,
    getResetFilter: (state) => state.resetFilter,
    getCategoryObjectIdByCategoryId: (state) => (id:number, lang:number) => new Promise(
      (resolve) => {
        const interval = setInterval(() => {
          const category = state.categoryFlatList.find((item) => item.id === id);
          if (category) {
            const categoryObject = category.categoryObjects.find(
              (e) => e.languageId === lang,
            );
            clearInterval(interval);
            resolve(categoryObject?.id);
          } else {
            clearInterval(interval);
            resolve(undefined);
          }
        }, 100);
        // Failsafe
        setTimeout(() => {
          clearInterval(interval);
          resolve(undefined);
        }, 7500);
      },
    ),
    getCategoryObjectIdByCategoryIdWithoutPromise: (state) => (id:number, lang:number) => {
      const category = state.categoryFlatList.find((item) => item.id === id);
      if (category) {
        return category.categoryObjects.find((e) => e.languageId === lang);
      }
      return undefined;
    },
    // eslint-disable-next-line max-len
    getCategoryTranslations: (state) => (id: number) => state.categoryFlatList.find((e) => e.id === id)?.categoryObjects,
    // eslint-disable-next-line max-len
    getCategoryTranslationsByObjectId: (state) => (objectId: number) => state.categoryFlatList.find((e) => e.categoryObjects.find((obj) => obj.id === objectId))?.categoryObjects,
    // eslint-disable-next-line max-len
    getCategoryIdByObjectId: (state) => (objectId: number) => {
      // eslint-disable-next-line max-len
      const category = state.categoryFlatList.find((e) => e.categoryObjects.find((obj) => obj.id === objectId));
      if (category) {
        return category.id;
      }
      return undefined;
    },
  },
  actions: {
    setActiveCategory(item: ICategory) {
      this.activeCategory = item;
    },
    setAdminItemsMap(item:TCategoryResourceTreeHashmap) {
      this.adminItemsMap = item;
    },
    setItemsMap(item:TCategoryResourceTreeHashmap) {
      this.categoryItemsMap = item;
    },
    setCategoryFlatList(item:ICategory[]) {
      this.categoryFlatList = item;
    },
    setAdminFlatList(item:IResources[]) {
      this.adminFlatList = item;
    },
    setResetFilter(item:boolean) {
      this.resetFilter = item;
    },
    async fetchCategories() {
      if (this.categoryFlatList.length) return Promise.resolve(true);
      try {
        const list = await getList(EEndpoint.CATEGORY);
        this.categoryFlatList = list as unknown as ICategory[];
      } catch (e) {
        console.error('fetchLanguagesData', e);
      }
      return Promise.resolve(true);
    },
  },

});

export default useCategory;
