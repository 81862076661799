/* eslint-disable max-len */
export enum EErrorCode {
  // special case for letting BE handle modal or toasts content and actions
  BE_PASSTHROUGH = 'BE_PASSTHROUGH',

  // Generic
  // some target entity is not found(checks before making changes to some data)
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  // multi action publish, if no _lang entry found with given ID
  LANGUAGE_ENTITY_NOT_FOUND = 'LANGUAGE_ENTITY_NOT_FOUND',
  // if any db operation fails while updating entity
  ENTITY_UPDATE_FAILED = 'ENTITY_UPDATE_FAILED',

  // Pagination
  // if orderByField and orderByDirection count is not the same
  ORDER_BY_COUNT_MISMATCH = 'ORDER_BY_COUNT_MISMATCH',
  // lower level - can only order by 1 level
  ORDER_BY_RELATION_PROPERTY_CONFLICT = 'ORDER_BY_RELATION_PROPERTY_CONFLICT',

  // Form generic
  // if opening service | event | place form but at the same time it is opened by another admin
  FORM_OPENED_BY_OTHER_ADMIN = 'FORM_OPENED_BY_OTHER_ADMIN',

  // Deleting
  // delete won't be allowed at all
  DELETION_NOT_POSSIBLE = 'DELETION_NOT_POSSIBLE',
  // delete will be allowed if confirmed (?force=true needs to be passed)
  DELETION_POSSIBLE_WITH_CONFIRMATION = 'DELETION_POSSIBLE_WITH_CONFIRMATION',

  // Authentication / Authorization
  // wrong username or password
  AUTH_INVALID_CREDENTIALS = 'AUTH_INVALID_CREDENTIALS',
  // user does not have usergroup added to them - log in/renew token aborted
  AUTH_MISSING_USERGROUP = 'AUTH_MISSING_USERGROUP',
  // wrong token is passed (refresh vs access)
  AUTH_INVALID_TOKEN_CLAIM = 'AUTH_INVALID_TOKEN_CLAIM',
  // authorization header is missing from request
  AUTH_MISSING_TOKEN = 'AUTH_MISSING_TOKEN',
  // token expired
  AUTH_EXPIRED_TOKEN = 'AUTH_EXPIRED_TOKEN',
  // token not found in jwt_token table
  INVALID_TOKEN = 'INVALID_TOKEN',
  // user needs to wait before trying to log in again
  AUTH_TIMEOUT_NOT_PASSED = 'AUTH_TIMEOUT_NOT_PASSED',

  // User
  // if something goes wrong when updating user
  // (values property will contain array of errors with errorCodes)
  USER_VALIDATION_FAILURE_GENERIC = 'USER_VALIDATION_FAILURE_GENERIC',
  // user can't delete/unpublish themselves
  USER_SELF_CONFLICT = 'USER_SELF_CONFLICT',

  // User group
  // if user tries to change fields that need special rights that user don't have
  USER_GROUP_WITHOUT_SPECIAL_RIGHTS = 'USER_GROUP_WITHOUT_SPECIAL_RIGHTS',
  // can't delete an usergroup which is still in use
  DELETION_NOT_POSSIBLE_USERGROUP_IN_USE = 'DELETION_NOT_POSSIBLE_USERGROUP_IN_USE',
  // can't unpublish an usergroup which is still in use
  UNPUBLISH_NOT_POSSIBLE_USERGROUP_IN_USE = 'UNPUBLISH_NOT_POSSIBLE_USERGROUP_IN_USE',

  // Site category
  // if usergroup does not have nevessary rights for that site category action
  SITE_CATEGORY_ACTION_NOT_ALLOWED = 'SITE_CATEGORY_ACTION_NOT_ALLOWED',
  // if usergroup does not have specialrights and tries to delete some category they did not create
  SITE_CATEGORY_DELETION_ONLY_POSSIBLE_IF_AUTHOR = 'SITE_CATEGORY_DELETION_ONLY_POSSIBLE_IF_AUTHOR',
  // if trying to add a document to a categoryObject that has a language id that does not exist or is not published
  NO_PUBLISHED_LANG_EXISTS = 'NO_PUBLISHED_LANG_EXISTS',

  // File service
  GENERATE_UNIQUE_FILE_NAME_RECURSION_MAX_EXCEEDED = 'GENERATE_UNIQUE_FILE_NAME_RECURSION_MAX_EXCEEDED',
  // if does not exist in minio in given bucket with given name
  FILE_DOES_NOT_EXIST = 'FILE_DOES_NOT_EXIST',
  // file not found in db (same as ENTITY_NOT_FOUND but file specific)
  FILE_NOT_FOUND_IN_DB = 'FILE_NOT_FOUND_IN_DB',
  // thrown if unsupported file is uploaded
  FILE_UNSUPPORTED_TYPE = 'FILE_UNSUPPORTED_TYPE',

  // if place publish_all / unpublish_all fails since some place has no values in published languages.
  // will contain other errors with code TRANSLATION_MISSING_IN_PUBLISHED_LANG
  TRANSLATION_MISSING_IN_PUBLISHED_LANG_GENERIC = 'TRANSLATION_MISSING_IN_PUBLISHED_LANG_GENERIC',
  // if user tries to publish an object that does not have a translation in published language
  TRANSLATION_MISSING_IN_PUBLISHED_LANG = 'TRANSLATION_MISSING_IN_PUBLISHED_LANG',

  // Deletion
  // If anything happens when trying delete something
  DELETING_FAILED = 'DELETING_FAILED',

  // Filters
  // lower level - should not arrive in FE (if provided key is not in object)
  PROPERTY_NOT_FOUND_IN_ENTITY = 'PROPERTY_NOT_FOUND_IN_ENTITY',
  // lower level - should not arrive in FE (if provided key supposedly is a relation but is not defined so in metadata)
  RELATION_NOT_FOUND_IN_ENTITY = 'RELATION_NOT_FOUND_IN_ENTITY',

  // Misconfiguration - should not appear in FE, only occur due to mistake in BE config
  // lower level -  (when trying to filer by relation that is not left joined)
  FILTER_RELATION_NOT_JOINED = 'FILTER_RELATION_NOT_JOINED',
  // if published is configured to be in same table as in base entity, but publish column does not exist
  PUBLISHED_MISSING_FROM_ENTITY = 'PUBLISHED_MISSING_FROM_ENTITY',
  // if published is configured to be in _lang table, but publish column does not exist
  PUBLISHED_MISSING_FROM_LANGUAGE_ENTITY = 'PUBLISHED_MISSING_FROM_LANGUAGE_ENTITY',
  // soft delete misconfiguration
  DELETE_CONFLICT = 'DELETE_CONFLICT',
  // permissions not defined in controller or endpoints
  PERMISSIONS_NOT_DEFINED = 'PERMISSIONS_NOT_DEFINED',

  // Category Media
  CATEGORY_MEDIA_ENTRY_ADDED_TO_DIFFERENT_CATEGORY = 'CATEGORY_MEDIA_ENTRY_ADDED_TO_DIFFERENT_CATEGORY',
  CATEGORY_OBJECT_DOCUMENT_LANGUAGE_CONFLICT = 'CATEGORY_OBJECT_DOCUMENT_LANGUAGE_CONFLICT',

  // lower level - when updating and id is passed through url and through body data,
  // ID_CONFLICT will be thrown if they do not match
  ID_CONFLICT = 'ID_CONFLICT',

  // Place
  // if something happens when validating place data prior to save/update
  // (values property will contain array of errors with errorCodes)
  PLACE_VALIDATION_FAILURE_GENERIC = 'PLACE_VALIDATION_FAILURE_GENERIC',
  // if something happens when updating place data
  // (values property will contain array of errors with errorCodes)
  PLACE_UPDATE_EXCEPTION_GENERIC = 'PLACE_UPDATE_EXCEPTION_GENERIC',
  // if something happens when saving place data
  // (values property will contain array of errors with errorCodes)
  PLACE_SAVE_EXCEPTION_GENERIC = 'PLACE_SAVE_EXCEPTION_GENERIC',

  // Editor
  // data-media-type or data-media-id is missing from tag
  EDITOR_RELATION_PROPERTY_MISSING = 'EDITOR_RELATION_PROPERTY_MISSING',
  // data-media-type inside the tag does not match the EDataMediaType enum
  EDITORY_RELATION_PROPERTY_CONFLICT = 'EDITORY_RELATION_PROPERTY_CONFLICT',
  // value in attribute data-media-id is not a number
  EDITOR_RELATION_ID_TYPE_ERROR = 'EDITOR_RELATION_ID_TYPE_ERROR',

  // low level error, it should not arrive to FE
  DIFFERENCE_INPUT_CONFLICT = 'DIFFERENCE_INPUT_CONFLICT',

  // Service
  // if something happens when validating service prior to save/update
  // (values property will contain array of errors with errorCodes)
  SERVICE_VALIDATION_FAILURE_GENERIC = 'SERVICE_VALIDATION_FAILURE_GENERIC',
  // if something happens when saving service data
  // (values property will contain array of errors with errorCodes)
  SERVICE_SAVE_EXCEPTION_GENERIC = 'SERVICE_SAVE_EXCEPTION_GENERIC',
  // if something happens when updating service data
  // (values property will contain array of errors with errorCodes),
  SERVICE_UPDATE_EXCEPTION_GENERIC = 'SERVICE_UPDATE_EXCEPTION_GENERIC',

  // Redirect
  // checking urlalias uniqueness before save/update
  DUPLICATE_REDIRECT_URL_ALIAS = 'DUPLICATE_REDIRECT_URL_ALIAS',

  // Admin-category
  // checking urlalias uniqueness before save/update
  DUPLICATE_ADMIN_CATEGORY_URL_ALIAS = 'DUPLICATE_ADMIN_CATEGORY_URL_ALIAS',

  // Event
  // if something happens when validating tc event prior to save/update
  // (values property will contain array of errors with errorCodes)
  EVENT_VALIDATION_FAILURE_GENERIC = 'EVENT_VALIDATION_FAILURE_GENERIC',
  // if something happens when saving event data
  // (values property will contain array of errors with errorCodes)
  EVENT_SAVE_EXCEPTION_GENERIC = 'EVENT_SAVE_EXCEPTION_GENERIC',
  // if something happens when updating event data
  // (values property will contain array of errors with errorCodes)
  EVENT_UPDATE_EXCEPTION_GENERIC = 'EVENT_UPDATE_EXCEPTION_GENERIC',

  // Event-type
  // can't add itself as a parent
  EVENT_CHILD_PARENT_CONFLICT = 'EVENT_CHILD_PARENT_CONFLICT',

  // Tallincard
  // if something happens when validating tc category data prior to save/update
  // (values property will contain array of errors with errorCodes)
  TALLINNCARD_CATEGORY_FAILURE_GENERIC = 'TALLINNCARD_CATEGORY_FAILURE_GENERIC',
  // if something happens when updating tc category data
  // (values property will contain array of errors with errorCodes)
  TALLINNCARD_CATEGORY_UPDATE_EXCEPTION_GENERIC = 'TALLINNCARD_CATEGORY_UPDATE_EXCEPTION_GENERIC',
  // if something happens when saving tc category data
  // (values property will contain array of errors with errorCodes)
  TALLINNCARD_CATEGORY_SAVE_EXCEPTION_GENERIC = 'TALLINNCARD_CATEGORY_SAVE_EXCEPTION_GENERIC',

  // if validating place or service data and passed seasons are already in use
  SEASON_ALREADY_IN_USE = 'SEASON_ALREADY_IN_USE',

  // Article
  // if something happens when validating article data prior to save/update
  // (values property will contain array of errors with errorCodes)
  ARTICLE_VALIDATION_FAILURE_GENERIC = 'ARTICLE_VALIDATION_FAILURE_GENERIC',
  // if something happens when saving article data
  // (values property will contain array of errors with errorCodes)
  ARTICLE_SAVE_EXCEPTION_GENERIC = 'ARTICLE_SAVE_EXCEPTION_GENERIC',
  // if something happens when updating article data
  // (values property will contain array of errors with errorCodes)
  ARTICLE_UPDATE_EXCEPTION_GENERIC = 'ARTICLE_UPDATE_EXCEPTION_GENERIC',

  // Guide
  // if something happens when validating guide data prior to save/update
  // (values property will contain array of errors with errorCodes)
  GUIDE_VALIDATION_FAILURE_GENERIC = 'GUIDE_VALIDATION_FAILURE_GENERIC',
  // if something happens when saving guide data
  // (values property will contain array of errors with errorCodes)
  GUIDE_SAVE_EXCEPTION_GENERIC = 'GUIDE_SAVE_EXCEPTION_GENERIC',
  // if something happens when saving guide data
  // (values property will contain array of errors with errorCodes)
  GUIDE_UPDATE_EXCEPTION_GENERIC = 'GUIDE_UPDATE_EXCEPTION_GENERIC',

  // Guide language
  // checking short name uniqueness before save/update
  DUPLICATE_SHORT_NAME_NOT_ALLOWED = 'DUPLICATE_SHORT_NAME_NOT_ALLOWED',

  // Mailgroup
  // if trying to delete a mailgroup but users are still subscribed to it
  MAILGROUP_VISITORS_STILL_SUBSCRIBED = 'MAILGROUP_VISITORS_STILL_SUBSCRIBED',
}
