import { useRoute } from 'vue-router';
import { IFileResponse } from '@/interfaces/file';
import { IMediaDetail } from '@/interfaces/media';
import api from './api';

export const addImage = async (file: IFileResponse): Promise<IMediaDetail> => {
  try {
    const route = useRoute();
    const { id: fileId, originalName } = file;
    const imageData = {
      fileId,
      translations: [
        {
          lang: route?.params?.lang || 'est',
          name: `(${originalName})`,
        },
      ],
    };
    const { data } = await api.post('admin/media/image', imageData);
    return Promise.resolve(data);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};
