import { defineStore } from 'pinia';
import { parseJwt } from '@/utils/parseJwt';
import Cookies from 'js-cookie';
import { EAuthPermission } from '@admin-shared/enums/auth/auth-permission.enum';
import { IUserGroupResponse, IUserState } from '@/interfaces/user';
import { getItemById } from '@/services/listService';
import { EEndpoint } from '@/enums/api';
import { handleError } from '@/utils/handleError';
import { ICategoryRights } from '@/interfaces/category';
import EPrivilege from '@/enums/user';

const initialState = {
  userData: {
    activeUserGroup: {
      description: null,
      id: 0,
      key: '',
      name: '',
      specialRights: false,
    },
    username: '',
    firstName: '',
    lastName: '',
    id: 0,
    langId: 0,
    email: '',
    phone: null,
  },
  permission: {
    grantedKeys: [] as EAuthPermission[],
  },
  categories: [],
  categoryLangId: undefined,
  defaultAdminCategoryId: null,
};

const useUserStore = defineStore('user', {
  state: ():IUserState => ({ ...initialState }),
  getters: {
    getFirstName: (state) => state.userData.firstName,

    getId: (state) => state.userData.id,
    getLanguageId: (state) => state.userData.langId,

    getUserGroupId: (state) => state.userData.activeUserGroup.id,
    getUserGroupKey: (state) => state.userData.activeUserGroup.key,
    getCategoryLangId: (state) => state.categoryLangId,

    getUserRightsForCategory: (state) => (categoryId:number) => {
      const currentCategory = state.categories.find(
        (category:ICategoryRights) => category.categoryId === categoryId,
      );

      if (currentCategory) {
        const {
          read, write, publish, dirWrite, dirPublish,
        } = currentCategory;
        return {
          read, write, publish, dirWrite, dirPublish,
        };
      }

      return {
        read: false,
        write: false,
        publish: false,
        dirWrite: false,
        dirPublish: false,
      };
    },

    hasPermission: (state) => (key:EAuthPermission) => state.permission.grantedKeys.includes(key),

    hasSpecialRights: (state) => state.userData.activeUserGroup.specialRights,
    isDeveloper: (state) => state.userData.activeUserGroup.id === 6,
    getSpecialPrivileges: (state): EPrivilege[] => (
      state.userData.activeUserGroup.specialRights ? [EPrivilege.SPECIAL] : []
    ),
    getDefaultAdminCategory: (state) => state.defaultAdminCategoryId,

  },
  actions: {
    async getUserInfo() {
      const token = Cookies.get('token');
      if (token) {
        const { user, permission } = parseJwt(token);
        this.userData = user;
        this.permission = permission;
      }

      await this.fetchUserGroupData();
    },
    async fetchUserGroupData() {
      try {
        const {
          categories,
          languageId,
          defaultAdminCategoryId,
        } = await getItemById<IUserGroupResponse>(
          EEndpoint.USERGROUPS,
          this.getUserGroupId,
        );
        this.categories = categories;
        this.categoryLangId = languageId;
        this.defaultAdminCategoryId = defaultAdminCategoryId;
      } catch (e) {
        handleError(e);
      }
    },
    clearUserInfo() {
      this.$state = { ...initialState };
    },
  },
});

export default useUserStore;
