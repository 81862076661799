export enum EThumbnailKey {
  HEADER = 'HEADER',
  DETAIL = 'DETAIL',
  LIST = 'LIST',
  PORTRAIT = 'PORTRAIT',
}

export enum FitEnum {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill',
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export const thumbnailConfigs: {
  thumbnailKey: EThumbnailKey;
  resize: { width: number; height: number; fit: FitEnum };
}[] = [
  {
    thumbnailKey: EThumbnailKey.HEADER,
    resize: {
      width: 1700,
      height: 638,
      fit: FitEnum.COVER,
    },
  },
  {
    thumbnailKey: EThumbnailKey.DETAIL,
    resize: {
      width: 700,
      height: 525,
      fit: FitEnum.COVER,
    },
  },
  {
    thumbnailKey: EThumbnailKey.LIST,
    resize: {
      width: 400,
      height: 285,
      fit: FitEnum.COVER,
    },
  },
  {
    thumbnailKey: EThumbnailKey.PORTRAIT,
    resize: {
      width: 285,
      height: 400,
      fit: FitEnum.COVER,
    },
  },
];

export enum EPictureType {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

// which thumbs to generate
const generateThumbTemplates = {
  [EPictureType.PORTRAIT]: [EThumbnailKey.LIST, EThumbnailKey.PORTRAIT],
  [EPictureType.LANDSCAPE]: [EThumbnailKey.LIST, EThumbnailKey.DETAIL],
};

// config list for generating thumbs
export function getThumbs(type: EPictureType) {
  const array = generateThumbTemplates[type];
  return thumbnailConfigs.filter(({ thumbnailKey }) => array.includes(thumbnailKey));
}
