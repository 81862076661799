import axios, { AxiosError, AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import useAuth from '@/store/auth';
import router from '../router/index';

let tokensRefreshing = false;
const HTTP_UNAUTHORIZED = 401;
const BAD_REQUEST = 400;

const api:AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    // Wow, that's some weird ignores, but since axios doesn't make a setHeaders
    // function available I have to ignore the errors and write straight to the Record
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${Cookies.get('token') ?? ''}`;
    return config;
  },
);
api.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response) {
      if (err.response.status === HTTP_UNAUTHORIZED && !err.config.url.endsWith('auth') && !err.config.url.endsWith('auth/renew')) {
        if (tokensRefreshing) {
          // Await for the previous refresh request to finish
          console.info('Multiple requests wish to update tokens. Awaiting for primary request to finish.');
          // Return a new promise since this allows for wherever the original request was called
          // to keep handling promises correct.
          return new Promise((resolve, reject) => {
            // The interval that keeps waiting for the token refresh to finish
            const interval = setInterval(async () => {
              if (!tokensRefreshing) {
                // Clear the interval so that it won't keep looping old requests
                clearInterval(interval);
                // Do the actual response again.
                await api.request(err.config)
                  .then((retriedRes) => resolve(retriedRes))
                  .catch((retriedErr) => reject(retriedErr));
              }
            }, 200);
          });
        }
        try {
          const authStore = useAuth();
          tokensRefreshing = true;

          return authStore.renewToken().then(() => {
            tokensRefreshing = false;
            console.info('Retrying request: ', err.config.url);
            return api.request(err.config);
          }).catch((apiResponse: AxiosError) => {
            tokensRefreshing = false;
            // console.error(apiResponse);
            if (apiResponse.response?.status === BAD_REQUEST) {
              router.push({ name: 'logout' });
              console.warn('/admin/auth/renew request failed to update tokens.');
            }
            return Promise.reject(err);
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    } else {
      console.error('internal Server error');
    }

    // Reject the request since it failed for other reasons
    return Promise.reject(err);
  },
);

export default api;
