/* eslint-disable import/extensions */
import { IModuleConf } from '@/interfaces/moduleConf';
import { RouteRecordName, useRoute } from 'vue-router';
import { IListConf } from '@/interfaces/list';
import { IFormConf } from '@/interfaces/form';

const modules = {
  settings: () => import('../modulesConf/settings/index'),
  languages: () => import('../modulesConf/languages/index'),
  categories: () => import('../modulesConf/categories/index'),
  resources: () => import('../modulesConf/resources/index'),
  usergroup: () => import('../modulesConf/userGroups/index'),
  contacts: () => import('../modulesConf/contacts/index'),
  textlabel: () => import('../modulesConf/textLabel/index'),
  image: () => import('../modulesConf/images/index'),
  mediarights: () => import('../modulesConf/mediaRights/index'),
  otherpossibilities: () => import('../modulesConf/otherPossibilities/index'),
  dmcservices: () => import('../modulesConf/dmcServices/index'),
  guidelanguages: () => import('../modulesConf/guideLanguages/index'),
  categoryImage: () => import('../modulesConf/categoryImage/index'),
  categoryDocuments: () => import('../modulesConf/categoryDocuments/index'),
  categoryArticles: () => import('../modulesConf/categoryArticles/index'),
  newslisttags: () => import('../modulesConf/newslistTags/index'),
  guideeducation: () => import('../modulesConf/guideEducation/index'),
  technicalequipment: () => import('../modulesConf/technicalEquipment/index'),
  guidepartevaluations: () => import('../modulesConf/guidePartEvaluations/index'),
  area: () => import('../modulesConf/area/index'),
  lipsicons: () => import('../modulesConf/lipsIcons/index'),
  guidegeneralevaluations: () => import('../modulesConf/guideGeneralEvaluations/index'),
  guidequalifications: () => import('../modulesConf/guideQualifications/index'),
  logs: () => import('../modulesConf/logs/index'),
  movementtypes: () => import('../modulesConf/movementTypes/index'),
  place: () => import('../modulesConf/place/index'),
  placetype: () => import('../modulesConf/placeType/index'),
  servicetype: () => import('../modulesConf/serviceType/index'),
  placePriority: () => import('../modulesConf/placePriority'),
  country: () => import('../modulesConf/country/index'),
  redirect: () => import('../modulesConf/redirect/index'),
  service: () => import('../modulesConf/service/index'),
  placeService: () => import('../modulesConf/placeService/index'),
  document: () => import('../modulesConf/document/index'),
  eventtype: () => import('../modulesConf/eventType/index'),
  event: () => import('../modulesConf/event/index'),
  placecategorysettings: () => import('../modulesConf/placeCategorySettings/index'),
  tallinncardreductioncommentary: () => import('../modulesConf/tallinnCardReductionCommentary/index'),
  eventcategorysettings: () => import('../modulesConf/eventCategorySettings/index'),
  placeroom: () => import('../modulesConf/placeRoom/index'),
  servicecategorysettings: () => import('../modulesConf/serviceCategorySettings/index'),
  ticketcommentary: () => import('../modulesConf/ticketCommentary/index'),
  tallinncardusercomments: () => import('../modulesConf/tallinnCardUserComments/index'),
  mailgroup: () => import('../modulesConf/mailGroup/index'),
  tallinncardcampaign: () => import('../modulesConf/tallinnCardCampaigns/index'),
  guide: () => import('../modulesConf/guide/index'),
  tallinncardpartner: () => import('../modulesConf/tallinnCardPartners/index'),
  tallinncardcategory: () => import('../modulesConf/tallinnCardCategory/index'),
  visitorcounter: () => import('../modulesConf/visitorCounter/index'),
  article: () => import('../modulesConf/article/index'),
  administrators: () => import('../modulesConf/administrators/index'),
  reference: () => import('../modulesConf/reference/index'),
  tallinncardshoppingcart: () => import('../modulesConf/shoppingCart/index'),
  tallinncardappshoppingcart: () => import('../modulesConf/appShoppingCart/index'),
  notification: () => import('../modulesConf/notification/index'),
};

export type ModuleKey = keyof typeof modules

async function loadMyModule(module: ModuleKey): Promise<IModuleConf | null> {
  try {
    if (modules[module]) {
      const { default: defaultFunc } = await modules[module]();
      return defaultFunc;
    }
  } catch (e) {
    console.error('loadMyModule:', module, e);
  }

  return null;
}

// eslint-disable-next-line max-len
export async function loadListConfiguration(module: string | string[] | RouteRecordName): Promise<IListConf> {
  const data = await loadMyModule(module as ModuleKey);
  return { ...(data?.list ?? {}) } as IListConf;
}

// eslint-disable-next-line max-len
export async function loadFormConfiguration(module?: string | string[] | RouteRecordName): Promise<IFormConf> {
  const route = useRoute();
  const key = (module || route.params.module) as ModuleKey;
  const data = await loadMyModule(key);

  return { ...(data?.form ?? {}) } as IFormConf;
}
