import { useLoadingBar } from 'naive-ui';
import { LoadingBarInst } from 'naive-ui/es/loading-bar/src/LoadingBarProvider';
import { ref, watchEffect } from 'vue';

type TLoadingState = boolean | null
type LoadingBarAction = keyof LoadingBarInst

const actionMap = new Map<TLoadingState, LoadingBarAction>([
  [true, 'start'],
  [false, 'finish'],
  [null, 'error'],
]);

export function useLoading(initialState: TLoadingState = false) {
  const loadingBar = useLoadingBar();
  const loading = ref<TLoadingState>(initialState);

  watchEffect(() => loadingBar[actionMap.get(loading.value)!]());

  return {
    loading,
  };
}
