export enum EAuthPermission {
  // gives access to essential endpoints, so the platform would work as intended
  ESSENTIAL = 'ESSENTIAL',

  SITE_MODULES = 'SITE_MODULES',
  ADMIN_MODULES = 'ADMIN_MODULES',
  VARIABLE = 'VARIABLE',
  // USER = 'USER', // replaced with CONTACTS
  LANGUAGE = 'LANGUAGE',
  ADMIN_ACCOUNT = 'ADMIN_ACCOUNT',
  USER_GROUPS = 'USER_GROUPS',
  TOOLTIP = 'TOOLTIP',
  CATEGORY = 'CATEGORY',
  ADMIN_CATEGORY = 'ADMIN_CATEGORY',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  TEXT_LABEL = 'TEXT_LABEL',
  MEDIA_RIGHTS = 'MEDIA_RIGHTS',
  TECHNICAL_EQUIPMENT = 'TECHNICAL_EQUIPMENT',
  OTHER_POSSIBILITIES = 'OTHER_POSSIBILITIES',
  DMC_SERVICES = 'DMC_SERVICES',
  MOVEMENT = 'MOVEMENT',
  AREA = 'AREA',

  GUIDE = 'GUIDE',
  GUIDE_LANGUAGE = 'GUIDE_LANGUAGE',
  GUIDE_PART_EVALUATIONS = 'GUIDE_PART_EVALUATIONS',
  GUIDE_QUALIFICATION = 'GUIDE_QUALIFICATION',
  GUIDE_EDUCATION = 'GUIDE_EDUCATION',
  GUIDE_GENERAL_EVALUATIONS = 'GUIDE_GENERAL_EVALUATIONS',

  PLACE = 'PLACE',
  PLACE_TYPE = 'PLACE_TYPE',
  PLACE_CATEGORY = 'PLACE_CATEGORY',
  PLACE_CATEGORY_SETTING = 'PLACE_CATEGORY_SETTING',
  PLACE_PRIORITY = 'PLACE_PRIORITY',
  LIPS_ICONS = 'LIPS_ICONS',
  // PLACE_SPECIAL_INTEREST_GROUP = 'PLACE_SPECIAL_INTEREST_GROUP',

  SERVICE = 'SERVICE',
  SERVICE_TYPE = 'SERVICE_TYPE',
  SERVICE_CATEGORY_SETTING = 'SERVICE_CATEGORY_SETTING',

  EVENT = 'EVENT',
  EVENT_TYPE = 'EVENT_TYPE',
  EVENT_CATEGORY_SETTING = 'EVENT_CATEGORY_SETTING',

  ARTICLE = 'ARTICLE',
  NEWSLIST_TAG = 'NEWSLIST_TAG',
  ADMIN_LOG = 'ADMIN_LOG',
  TALLINNCARD = 'TALLINNCARD',
  SHOPPINGCART = 'SHOPPINGCART',

  SEASON = 'SEASON',
  PLACE_SEASON = 'PLACE_SEASON',

  COUNTRY = 'COUNTRY',
  REDIRECT = 'REDIRECT',

  VISITOR_COUNTER = 'VISITOR_COUNTER',

  MAILGROUP = 'MAILGROUP',

  CONTACTS = 'CONTACTS',

  TALLINNCARD_APP_SHOPPING_CARD = 'TALLINNCARD_APP_SHOPPING_CARD',
  TALLINNCARD_SHOPPING_CARD = 'TALLINNCARD_SHOPPING_CARD',
  TALLINNCARD_CAMPAIGN = 'TALLINNCARD_CAMPAIGN',
  TALLINNCARD_PARTNER = 'TALLINNCARD_PARTNER',
  TALLINNCARD_CUSTOMER_COMMENTS = 'TALLINNCARD_CUSTOMER_COMMENTS',
  TALLINNCARD_CATEGORY = 'TALLINNCARD_CATEGORY',
  TALLINNCARD_REDUCTION_COMMENTARY = 'TALLINNCARD_REDUCTION_COMMENTARY',

  TICKET_COMMENTARY = 'TICKET_COMMENTARY',

  NOTIFICATION = 'NOTIFICATION',
}
