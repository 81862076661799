import { EErrorCode } from './error-codes.enum';

export enum EPassThroughVariant {
  DIALOG = 'DIALOG',
  TOAST = 'TOAST',
}

type ParamsType = Record<string, any>;

export interface PassThroughErrorValueChild {
  label: string;
  params: ParamsType;
}

export enum EPassThroughDialogAction {
  DELETE = 'DELETE',
}

interface BasePassThrough {
  titleLabel: string;
  titleParams?: ParamsType;

  leaderLabel?: string;
  leaderParams?: ParamsType;

  children: PassThroughErrorValueChild[];
}

export interface IPassThroughErrorDialog extends BasePassThrough {
  type: EPassThroughVariant.DIALOG;
  action: {
    method: EPassThroughDialogAction;
    endpoint: string;
  };
}

export interface IPassThroughErrorToast extends BasePassThrough {
  type: EPassThroughVariant.TOAST;
}

export type PassThroughValues = (IPassThroughErrorDialog | IPassThroughErrorToast)[];

export interface IExceptionBePassThrough {
  errorCode: EErrorCode.BE_PASSTHROUGH;
  values: PassThroughValues;
}
