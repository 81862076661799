export enum EEndpoint {
  PUBLICTEXTLABELS = '/public/textlabel',

  CATEGORY = 'admin/categories',
  LANGUAGE = 'admin/languages',
  SETTINGS = 'admin/variables',
  ADMIN_CATEGORY = 'admin/admin-categories',
  USERGROUPS = 'admin/user-groups',
  CONTACT = '/admin/contact',
  USERSSIMPLE = '/admin/admin-accounts/simple',
  IMAGE = '/admin/media/image',
  IMAGESORT = 'admin/categories/image-to-category-sort',
  MEDIA_RIGHTS = 'admin/media-rights',
  TEXTLABELS = '/admin/textlabel',
  MEDIARIGHTS = '/admin/media-rights',
  MOVEMENT_TYPES = '/admin/movement',
  OTHER_POSSIBILITIES = '/admin/other-possibilities',
  DMC_SERVICES = '/admin/dmc-services',
  NEWSLIST_TAG = '/admin/newslist-tag',
  GUIDE_EDUCATION = '/admin/guide-education',
  GUIDE_PART_EVALUATIONS = '/admin/guide-part-evaluations',
  GUIDE_LANGUAGE = '/admin/guide-language',
  TECHNICAL_EQUIPMENT = '/admin/equipment',
  LIPS_ICONS = '/admin/lips-icons',
  AREA = '/admin/area',
  GUIDE_GENERAL_EVALUATIONS = '/admin/guide-general-evaluations',
  GUIDE_QUALIFICATIONS = '/admin/guide-qualification',
  LOGS = '/admin/log',
  SPECIAL_INTEREST_GROUP = '/admin/special-interest-group',
  PLACE = '/admin/place',
  PLACETYPE = '/admin/place-type',
  SEASON = '/admin/season',
  PLACE_PRIORITY = '/admin/place-priority',
  SERVICETYPE = '/admin/service-type',
  COUNTRY = '/admin/country',
  SERVICE = '/admin/service',
  REDIRECT = '/admin/redirect',
  DOCUMENT = '/admin/media/document',
  DOCUMENTSORT = '/admin/categories/document-to-category-object-sort',
  EVENT_TYPE = '/admin/event-type',
  EVENT = '/admin/event',
  PLACE_CATEGORY_SETTINGS = '/admin/place-category-settings',
  SHOPPING_CARD = '/admin/tallinncard-shopping-card',
  APP_SHOPPING_CARD = '/admin/tallinncard-app-shopping-card',
  PLACE_ROOM = '/admin/place-room',
  EVENT_CATEGORY_SETTINGS = '/admin/event-category-settings',
  SERVICE_CATEGORY_SETTINGS = '/admin/service-category-settings',
  TICKET_COMMENTARY = '/admin/ticket-commentary',
  MAILGROUP = '/admin/mailgroup',
  ADMIN_ACCOUNTS_SIMPLE = '/admin/admin-accounts/simple',
  ADMIN_ACCOUNTS = '/admin/admin-accounts',
  TALLINNCARD_CAMPAIGN = '/admin/tallinncard-campaign',
  TALLINNCARD_CATEGORY = '/admin/tallinncard-category',
  TALLINNCARD_PRODUCT = '/admin/tallinncard-product',
  TALLINNCARD_CUSTOMER_COMMENTS = '/admin/tallinncard-customer-comments',
  TALLINNCARD_REDUCTION_COMMENTARY = '/admin/tallinncard-reduction-commentary',
  GUIDE = '/admin/guide',
  TALLINNCARD_PARTNER = '/admin/tallinncard-partner',
  VISITOR_COUNTER = '/admin/visitor-counter',
  ARTICLE = '/admin/article',
  ARTICLESORT = '/admin/categories/article-to-category-object-sort',

  NOTIFICATION = '/admin/notification',
}

export enum ESortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  ascend = 'ASC',
  descend = 'DESC',
}
