/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
// Feel free to improve on this, currently not very good but allows you to wait for props to update etc.
export const waitForData = (obj: any, key: any, callback?: (...args: any[]) => any, callbackArgs?: any[]) => new Promise((resolve) => {
  const interval = setInterval(() => {
    if (obj) {
      if (obj[key]) {
        clearInterval(interval);
        if (callback) {
          const args = callbackArgs || [];
          resolve(callback.call(undefined, ...args));
        }
        resolve(undefined);
      }
    }
  }, 100);
  // Fallback to prevent the promise from never resolving
  setTimeout(() => {
    clearInterval(interval);
    if (obj) {
      if (obj[key]) {
        if (callback) {
          const args = callbackArgs || [];
          resolve(callback.call(undefined, ...args));
        }
      }
    }
    resolve(undefined);
  }, 7500);
});
