import { defineStore } from 'pinia';
import { FormValidationError } from 'naive-ui';

type TValidationError = FormValidationError[] | undefined;

const useValidationErrors = defineStore('validationErrors', {
  state: () => ({
    validationErrors: undefined as TValidationError,
  }),
  getters: {
    getValidationErrors: (state) => state.validationErrors,
  },
  actions: {
    setValidationError(errors: FormValidationError[]) {
      this.validationErrors = errors;
    },
    clearValidationErrors() {
      this.validationErrors = undefined;
    },
  },
});

export default useValidationErrors;
