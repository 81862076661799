import { RouteLocationNormalized } from 'vue-router';
import Cookies from 'js-cookie';
import i18n, { setI18nLanguage, SUPPORTED_LOCALES } from '@/plugins/i18n';
import routesMap from '@/router/routesMap';
import { EAuthPermission } from '@admin-shared/enums/auth/auth-permission.enum';
import router from '@/router';

export const resolveRoute = (path:string) => {
  const vueRoute = router.currentRoute.value.params.locale;
  if (path === 'categories' || path === 'resources') {
    return router.resolve({
      name: path, params: { locale: vueRoute, lang: 'eng' },
    });
  }
  return router.resolve({ name: 'module', params: { locale: vueRoute, module: path } });
};

export const maybeUpdateLang = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<string> => {
  // Evaluate whether or not the URL contains a language
  const hasLang = !!(to.params && to.params.locale);
  const hadLang = !!(from.params && from.params.locale);
  // If the language hasn't changed since last route we're done
  if (
    hasLang
    && hadLang
    && from.params.locale?.toString().toLowerCase() === to.params.locale?.toString().toLowerCase()
  ) {
    return Promise.resolve('');
  }

  // Get the language
  let lang = process.env.VUE_APP_DEFAULT_LOCALE || 'et';

  if (navigator.language) {
    lang = navigator.language.slice(0, 2);
  }

  const cookieLang = Cookies.get('selectedLang');
  if (cookieLang) {
    lang = cookieLang;
  }
  // Overwrite the language with the route if there is one
  if (hasLang) {
    lang = to.params.locale.toString().toLowerCase();
  }

  let hasCorrectLang = true;
  // Make sure the language is valid
  if (!SUPPORTED_LOCALES.some(({ short }) => short === lang)) {
    lang = process.env.VUE_APP_DEFAULT_LOCALE;
    hasCorrectLang = false;
  }

  // Set the website language based on the URL
  await setI18nLanguage(i18n, lang);

  // Redirect to a url with the language
  if (!hasLang) {
    return Promise.resolve(`/${lang}${to.fullPath}`);
  }

  // If is a incorrect locale remove the old locale and substitute the new one.
  if (!hasCorrectLang) {
    const [,, ...pathPieces] = to.fullPath.split('/');
    return Promise.resolve(`/${lang}/${pathPieces.join('/')}`);
  }

  return Promise.resolve('');
};

const findRoutePermission = (route:string) => Object.keys(routesMap).find(
  (key) => routesMap[EAuthPermission[key as keyof typeof EAuthPermission]] === route,
) as EAuthPermission;

export const getRoutePermission = (route: RouteLocationNormalized):EAuthPermission => {
  const { params, name } = route;
  if (params?.module) {
    return findRoutePermission(params.module as string);
  }

  return findRoutePermission(name as string);
};
export default {
  maybeUpdateLang,
};
