import { IRow } from '@/interfaces/list';
import api from '@/services/api';
import { IFormData } from '@/interfaces/form';
import { AxiosResponse } from 'axios';

export const getTooltips = async (formKey:string):
  Promise<IRow[]> => api.get('tooltips', { params: { formKey } }).then(({ data }) => data);

export const postTooltip = async (data:IFormData): Promise<AxiosResponse> => api.post('tooltips', data);

export const updateTooltip = async (id:number, data:IFormData):Promise<AxiosResponse> => api.put(`tooltips/${id}`, data);

export const deleteTooltip = async (id:number):Promise<AxiosResponse> => api.delete(`tooltips/${id}`);
