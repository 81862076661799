import { TAuth } from '@/types/categoryTypes';

const routesMap: Partial<TAuth> = {
  VARIABLE: 'settings',
  LANGUAGE: 'languages',
  CATEGORY: 'categories',
  ADMIN_CATEGORY: 'resources',
  USER_GROUPS: 'usergroup',
  CONTACTS: 'contacts',
  ADMIN_ACCOUNT: 'administrators',
  TEXT_LABEL: 'textlabel',
  IMAGE: 'image',
  MEDIA_RIGHTS: 'mediarights',
  OTHER_POSSIBILITIES: 'otherpossibilities',
  DMC_SERVICES: 'dmcservices',
  NEWSLIST_TAG: 'newslisttags',
  GUIDE_EDUCATION: 'guideeducation',
  GUIDE_LANGUAGE: 'guidelanguages',
  TECHNICAL_EQUIPMENT: 'technicalequipment',
  GUIDE_PART_EVALUATIONS: 'guidepartevaluations',
  AREA: 'area',
  LIPS_ICONS: 'lipsicons',
  GUIDE_GENERAL_EVALUATIONS: 'guidegeneralevaluations',
  GUIDE_QUALIFICATION: 'guidequalifications',
  MOVEMENT: 'movementtypes', // movement types
  ADMIN_LOG: 'logs',
  PLACE: 'place',
  PLACE_TYPE: 'placetype',
  SERVICE_TYPE: 'servicetype',
  COUNTRY: 'country',
  REDIRECT: 'redirect',
  SERVICE: 'service',
  EVENT_TYPE: 'eventtype',
  EVENT: 'event',
  DOCUMENT: 'document',
  PLACE_CATEGORY_SETTING: 'placecategorysettings',
  TALLINNCARD_REDUCTION_COMMENTARY: 'tallinncardreductioncommentary',
  TALLINNCARD_SHOPPING_CARD: 'tallinncardshoppingcart',
  TALLINNCARD_APP_SHOPPING_CARD: 'tallinncardappshoppingcart',
  EVENT_CATEGORY_SETTING: 'eventcategorysettings',
  SERVICE_CATEGORY_SETTING: 'servicecategorysettings',
  TICKET_COMMENTARY: 'ticketcommentary',
  ARTICLE: 'article',
  VISITOR_COUNTER: 'visitorcounter',
  TALLINNCARD_PARTNER: 'tallinncardpartner',
  GUIDE: 'guide',
  TALLINNCARD_CAMPAIGN: 'tallinncardcampaign',
  TALLINNCARD_CATEGORY: 'tallinncardcategory',
  TALLINNCARD_CUSTOMER_COMMENTS: 'tallinncardusercomments',
  MAILGROUP: 'mailgroup',
  NOTIFICATION: 'notification',
};

export default routesMap;
