import router from '@/router';
import useLanguage from '@/store/language';
import useUserStore from '@/store/useUser';
import { ILanguage } from '@/interfaces/language';

const useLanguageTabs = () => {
  const languageStore = useLanguage();
  const { hasSpecialRights } = useUserStore();

  const getTabs = (allowedLanguagesIds: number[] | undefined):ILanguage[] => {
    if (allowedLanguagesIds && allowedLanguagesIds?.length) {
      const allowedLanguages:ILanguage[] = allowedLanguagesIds.map(
        (langId:number) => languageStore.getLanguageById(langId) as ILanguage,
      );

      router.replace({ params: { lang: allowedLanguages[0]?.brief } });
      return allowedLanguages;
    }
    if (hasSpecialRights) {
      return languageStore.allLanguages;
    }
    return languageStore.webLanguages();
  };

  return {
    getTabs,
  };
};

export default useLanguageTabs;
