import { getList } from '@/services/listService';
import { EEndpoint } from '@/enums/api';
import { handleError } from '@/utils/handleError';

export const getUserName = async (id: number, type: 'user' | 'admin' = 'admin') => {
  const endpoint = type === 'user' ? EEndpoint.CONTACT : EEndpoint.ADMIN_ACCOUNTS;
  try {
    const data = await getList(`${endpoint}/${id}`);
    const { userName, lastName } = data as unknown as { userName: string; lastName: string };
    return `${userName || ''} ${lastName || ''}`;
  } catch (e) {
    handleError(e);
    return '--';
  }
};
