import api from '@/services/api';
import { IFormData } from '@/interfaces/form';
import { ICategory } from '@/interfaces/category';
import { IResources } from '@/interfaces/resources';
import { IListParams } from '@/interfaces/api';
import { EEndpoint, ESortOrder } from '@/enums/api';
import { handleError } from '@/utils/handleError';

export const getCategory = async (filters:IListParams):Promise<ICategory[]> => {
  const defaultParams:IListParams = {};

  defaultParams.orderByDirection = ESortOrder.ASC;
  defaultParams.orderByField = 'sort';

  const params:IListParams = { ...defaultParams, ...filters };

  return api.get<ICategory[]>('admin/categories', { params }).then(({ data }:{data: ICategory[]}) => data);
};

export const checkUrlAlias = async (
  urlAlias:string,
  parentId: number,
  languageId:number,
  categoryObjectId?:number,
):Promise<boolean> => {
  const config = {
    languageId,
    parentId,
    urlAlias,
    categoryObjectId,
  };

  try {
    const response = await api.post<boolean>(`${EEndpoint.CATEGORY}/url-alias`, config);
    if (response.status === 201) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  } catch (e) {
    return Promise.resolve(false);
  }
};

export const getResources = async ():Promise<IResources[]> => {
  const params:IFormData = {};

  params.orderByDirection = 'ASC';
  params.orderByField = 'sort';

  return api.get<IResources[]>(EEndpoint.ADMIN_CATEGORY, { params })
    .then(({ data }:{data: IResources[]}) => data);
};

const getMediaPath = (
  categoryId: number,
  mediaId?:number,
) => `${EEndpoint.CATEGORY}/image-to-category/${categoryId}/${mediaId || ''}`;
const getDocumentPath = (
  categoryObjectId: number,
  mediaId?:number,
) => `${EEndpoint.CATEGORY}/document-to-category-object/${categoryObjectId}/${mediaId || ''}`;

export const addMediaToCategory = async (categoryId: number, mediaId:number) => {
  if (!categoryId || !mediaId) {
    return Promise.reject();
  }
  try {
    await api.post(getMediaPath(categoryId, mediaId));
    return Promise.resolve();
  } catch (e) {
    handleError(e);
    return Promise.reject();
  }
};
export const addDocumentToCategory = async (categoryObjectId: number, mediaId:number) => {
  if (!categoryObjectId || !mediaId) {
    return Promise.reject();
  }
  try {
    await api.post(getDocumentPath(categoryObjectId, mediaId));
    return Promise.resolve();
  } catch (e) {
    handleError(e);
    return Promise.reject();
  }
};

export const removeDocumentFromCategory = async (categoryObjectId: number, mediaId:number[]) => {
  try {
    if (!categoryObjectId || !mediaId) {
      throw new Error(`removeMediaFromCategory: missing data -categoryObjectId:${categoryObjectId} mediaId:${mediaId}`);
    }
    await api.delete(getDocumentPath(categoryObjectId), {
      data: { ids: [...mediaId] },
    });
    return Promise.resolve();
  } catch (e) {
    handleError(e);
    return Promise.reject(e);
  }
};
export const removeMediaFromCategory = async (categoryId: number, mediaId:number[]) => {
  try {
    if (!categoryId || !mediaId) {
      throw new Error(`removeMediaFromCategory: missing data -categoryId:${categoryId} mediaId:${mediaId}`);
    }
    await api.delete(getMediaPath(categoryId), {
      data: { ids: mediaId },
    });
    return Promise.resolve();
  } catch (e) {
    handleError(e);
    return Promise.reject(e);
  }
};
