import { EEndpoint } from '@/enums/api';
import { IArticleList } from '@/interfaces/article';
import { IList } from '@/interfaces/list';
import { handleError } from '@/utils/handleError';
import { getAncestors } from '@/composables/CategoryTree';
import { getLanguageCode } from '@/utils/language';
import { getList } from './listService';

export const fetchArticlesByAuthorId = async (id: number) => {
  try {
    const { list } = await getList<IList<IArticleList>>(EEndpoint.ARTICLE, { authorId: id });
    const relatedArticles = list
      .map((row) => ({
        id: row.id,
        headline: row.headline,
        published: row.published,
        categoryObject: {
          id: row.categoryObject.id,
          name: row.categoryObject.name,
          published: row.categoryObject.published,
          languageId: row.categoryObject.languageId,
          categoryId: row.categoryObject.categoryId,
        },
      }))
      .sort((a, b): number => {
        if (a.published === b.published) {
          return 0;
        }
        if (a.published) {
          return -1;
        }
        return 1;
      })
      .sort((a, b): number => {
        if (a.categoryObject.published === b.categoryObject.published) {
          return 0;
        }
        if (a.categoryObject.published) {
          return -1;
        }
        return 1;
      });
    return relatedArticles;
  } catch (e) {
    handleError(e);
    return [];
  }
};

export const fetchMultipleArticlesById = async (params: string) => {
  const { list } = await getList<IList<IArticleList>>(EEndpoint.ARTICLE, { id: params });
  if (!list) {
    return [];
  }
  const articles = Promise.all(list
    .map(async (row) => ({
      id: row.id,
      headline: row.headline,
      published: row.published,
      categoryObject: {
        id: row.categoryObject.id,
        name: row.categoryObject.name,
        published: row.categoryObject.published,
        languageId: row.categoryObject.languageId,
        categoryId: row.categoryObject.categoryId,
      },
      // eslint-disable-next-line max-len
      ancestors: await getAncestors(row.categoryObject.categoryId, getLanguageCode(row.categoryObject.languageId) as string),
    })));
  return articles;
};
