import { EThumbnailKey } from '@admin-shared/constants/files/thumbnails.constant';
import { defineStore } from 'pinia';
import { IFileResponse } from '@/interfaces/file';
import { EMediaDetailType, IMediaDetail, TMediaDetailState } from '@/interfaces/media';

const defaultState = {
  id: undefined,
  mediaType: undefined,
  videoImageId: undefined,
  fileId: undefined,
  file: undefined,
  mediaRightsId: undefined,
  author: '',
  date: undefined,
  showOriginal: false,
  published: false,
  categories: [],
  translations: [],
};

const useMediaDetail = defineStore('mediaDetail', {
  state: (): TMediaDetailState => ({
    // Break reference, otherwise updating one updates the other too
    document: { ...defaultState },
    image: { ...defaultState },
  }),
  getters: {
    getFileObject: (state) => (type: EMediaDetailType) => state[type].file,
    getTranslationsObject: (state) => (type: EMediaDetailType) => state[type].translations,
    getThumbnails: (state) => (type: EMediaDetailType) => state[type].file?.thumbnails || [],
    getThumbnailCropRegion: (state) => (type: EMediaDetailType, thumbnailKey: EThumbnailKey) => {
      const region = state[type].file?.thumbnails.find(
        (thumb) => thumb.thumbnailKey === thumbnailKey,
      );
      return region?.cropRegion || null;
    },
    getFileId: (state) => (type: EMediaDetailType) => state[type].fileId,
  },
  actions: {
    setState(stateData: IMediaDetail, type: EMediaDetailType) {
      this.$reset();
      this.$state[type] = { ...stateData };
    },
    setFile(fileResponse: IFileResponse, type: EMediaDetailType) {
      this[type].fileId = fileResponse.id;
      this[type].file = fileResponse;
    },
    setImage(file: IFileResponse) {
      if (file) {
        this[EMediaDetailType.IMAGE].file = file;
        this[EMediaDetailType.IMAGE].fileId = file.id;
      }
    },
    deleteImage() {
      this[EMediaDetailType.IMAGE] = { ...defaultState };
    },
    deleteDocument() {
      this[EMediaDetailType.DOCUMENT] = { ...defaultState };
    },
  },
});

export default useMediaDetail;
