import { defineStore } from 'pinia';
import { ILanguage } from '@/interfaces/language';
import { getList } from '@/services/listService';
import { EEndpoint, ESortOrder } from '@/enums/api';

const useLanguage = defineStore('language', {
  state: () => ({
    languages: [] as ILanguage[],
  }),
  getters: {
    webLanguages: (state) => (published?: boolean):ILanguage[] => {
      const wl = state.languages.filter((lang: ILanguage) => lang.site);
      if (published) {
        return wl.filter((language:ILanguage) => language.published);
      }
      return wl;
    },
    cmsLanguages: (state) => state.languages.filter((lang:ILanguage) => lang.admin),
    // eslint-disable-next-line max-len
    allLanguages: (state) => state.languages,
    // eslint-disable-next-line max-len
    getLanguage: (state) => (brief: string) => state.languages.find((lang:ILanguage) => lang.brief === brief),
    // eslint-disable-next-line max-len
    getLanguageId: (state) => (brief: string) => state.languages.find((lang:ILanguage) => lang.brief === brief)?.id,
    // eslint-disable-next-line max-len
    getLanguageById: (state) => (id: number) => state.languages.find((lang: ILanguage) => lang.id === id),
    // eslint-disable-next-line max-len
    getLanguageNameById: (state) => (id:number) => state.languages.find((lang: ILanguage) => lang.id === id)?.brief,
  },
  actions: {
    async fetchLanguagesData() {
      try {
        const { list } = await getList(
          EEndpoint.LANGUAGE,
          { orderByField: 'sort', orderByDirection: ESortOrder.ASC },
        );
        this.languages = list as unknown as ILanguage[];
      } catch (response) {
        // console.error('fetchLanguagesData', response);
      }
      return Promise.resolve(true);
    },
  },
});

export default useLanguage;
