import { useDialog } from 'naive-ui';
import i18n from '@/plugins/i18n';

const { t } = i18n.global;

export function useFormConfirmation() {
  const dialog = useDialog();

  const saveChanges = () => new Promise((resolve) => {
    dialog.warning({
      title: t('formConfirm_title'),
      content: t('formConfirm_content'),
      positiveText: t('formConfirm_positive'),
      negativeText: t('formConfirm_negative'),
      onPositiveClick: () => {
        resolve(true);
      },
      onNegativeClick: () => {
        resolve(false);
      },
    });
  });

  return {
    saveChanges,
  };
}
