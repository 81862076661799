import { createI18n, I18n } from 'vue-i18n';
import axios from 'axios';
import { nextTick } from 'vue';
import { EEndpoint } from '@/enums/api';
import { handleError } from '@/utils/handleError';

export type LocaleWithLong = {
  short: string,
  long: string
}
export const SUPPORTED_LOCALES: LocaleWithLong[] = [{ short: 'en', long: 'ENG' }, { short: 'et', long: 'EST' }, { short: 'ru', long: 'RUS' }, { short: 'fi', long: 'FIN' }];

const labelsHashMap: { [key: string]: any } = {
  en: {},
  et: {},
};

// eslint-disable-next-line max-len
export async function loadLocaleMessages(lI18n: I18n<unknown, unknown, unknown, false>, locale: string) : Promise<void> {
  // load locale messages with dynamic import
  try {
    const { data } = await axios.get(`${process.env.VUE_APP_API_BASE_URL}${EEndpoint.PUBLICTEXTLABELS}`, { params: { isAdmin: true } });

    // eslint-disable-next-line no-restricted-syntax
    for await (const label of data) {
    // eslint-disable-next-line max-len,no-unused-expressions
      (label.labelEng) ? labelsHashMap.en[label.keyword] = label.labelEng : labelsHashMap.en[label.keyword] = null;

      // eslint-disable-next-line max-len,no-unused-expressions
      (label.labelEst) ? labelsHashMap.et[label.keyword] = label.labelEst : labelsHashMap.et[label.keyword] = null;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lI18n.global.setLocaleMessage(locale, labelsHashMap[locale]);
  } catch (e) {
    handleError(e);
  }
  return nextTick();
}

export async function setI18nLanguage(
  lI18n: I18n<unknown, unknown, unknown, false>,
  locale: string,
): Promise<void> {
  await loadLocaleMessages(lI18n, locale);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  lI18n.global.locale.value = locale;
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  const html = document.querySelector('html');
  if (html) html.setAttribute('lang', locale);
}

// Actually setting up the translations logic
const i18n: I18n<unknown, unknown, unknown, false> = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
});

(async () => setI18nLanguage(i18n, process.env.VUE_APP_DEFAULT_LOCALE))();

export default i18n;
