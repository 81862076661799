import { ref } from 'vue';
import { getCategory, getResources } from '@/services/categoryService';
import useLanguage from '@/store/language';
import useCategory from '@/store/category';
import {
  TCategoryResourceTree,
  TCategoryResourceTreeArray,
  TCategoryResourceTreeHashmap,
} from '@/types/categoryTypes';
import { IListParams } from '@/interfaces/api';
import { IPlaceType, TPlaceTypeTree, TPlaceTypeTreeArray } from '@/interfaces/placeType';
import { EEndpoint } from '@/enums/api';
import { getList } from '@/services/listService';
import { getLanguageString } from '@/utils/language';
import { ICategory, isCategory } from '@/interfaces/category';
import useUser from '@/store/useUser';

function getCategoryNaming(item: TCategoryResourceTree, routeLang: string) {
  const languageStore = useLanguage();
  const firstLanguageId = languageStore.getLanguage(routeLang)?.id;
  // current language Category object
  const currentLanguage = item.categoryObjects.find((j) => j.languageId === firstLanguageId);
  if (currentLanguage) {
    if (currentLanguage.name.trim().length <= 1) {
      return { ...currentLanguage, name: `[Tõlgi] [${currentLanguage.id}]` };
    }
    return currentLanguage;
  }
  // Replace language
  for (let l = 0; languageStore.languages.length > l; l += 1) {
    const lang = languageStore.languages[l];
    const langCategory = item.categoryObjects.find((j) => j.languageId === lang?.id);
    if (langCategory) {
      let name = item.categoryObjects?.find((i) => i.languageId === lang.id)?.name;
      if (name && name.trim().length <= 1) {
        name = `[${item.id}]`;
      }
      const fieldLabel = `${name}(${lang.brief})`;
      return { ...langCategory, name: fieldLabel, languageId: lang.id };
    }
  }

  return { name: '[Tõlgi]', published: false, languageId: 0 };
}

let itemsMap: TCategoryResourceTreeHashmap = {};

// eslint-disable-next-line max-len
async function mapTree(
  items: TCategoryResourceTreeArray,
  routeLang: string,
  filter = false,
  addId = false,
): Promise<TCategoryResourceTreeArray> {
  const { getUserRightsForCategory } = useUser();
  let tree: TCategoryResourceTreeArray = [];
  itemsMap = {};
  let firstLanguageId: number | undefined;
  if (filter) {
    const languageStore = useLanguage();
    firstLanguageId = languageStore.getLanguage(routeLang)?.id;
  }
  const mappedArr: TCategoryResourceTreeHashmap = {};
  items.forEach((item: TCategoryResourceTree) => {
    const localItem = { ...item };

    if (isCategory(localItem)) {
      localItem.disableDirWrite = !getUserRightsForCategory(localItem.id).dirWrite;
    }

    const { id } = localItem;
    itemsMap[id] = localItem;
    const { name, published, languageId } = getCategoryNaming(localItem, routeLang);

    if (!mappedArr[id] && filter && languageId === firstLanguageId) {
      mappedArr[id] = {
        ...localItem,
        label: `${name}${addId ? ` [${id}]` : ''}`,
        published,
        key: localItem.id,
        categoryObjectId: localItem.id,
      };
    }
    if (!mappedArr[id] && !filter) {
      mappedArr[id] = {
        ...localItem,
        label: `${name}${addId ? ` [${id}]` : ''}`,
        published,
        categoryObjectId: localItem.id,
      };
    }
  });
  Object.keys(mappedArr).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(mappedArr, key)) {
      const mappedElem = mappedArr[key];
      if (Object.prototype.hasOwnProperty.call(mappedArr, mappedElem.parent)) {
        const parentId = mappedElem.parent;
        if (!mappedArr[parentId].children) {
          mappedArr[parentId].children = [];
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedArr[parentId].children.push(mappedElem);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tree.push(mappedElem);
      }
    }
  });

  tree = tree.sort((a: TCategoryResourceTree, b: TCategoryResourceTree) => {
    if (a.sort > b.sort) {
      return 1;
    }
    if (a.sort < b.sort) {
      return -1;
    }

    return 0;
  });

  return tree;
}

// eslint-disable-next-line max-len
export const getCategoryTree = async (
  routeLang: string,
  params: IListParams = {},
  filter = false,
  addId = false,
): Promise<TCategoryResourceTreeArray> => {
  try {
    const categoryStore = useCategory();
    const tempData = await getCategory(params);
    const tree = await mapTree(tempData, routeLang, filter, addId);
    categoryStore.setItemsMap(itemsMap);
    categoryStore.setCategoryFlatList(tempData);
    return Promise.resolve(tree);
  } catch (e) {
    console.log('ERROR', e);
    return Promise.resolve([]);
  }
};

export const getPlaceTypeTree = async (
  currentFormId?: number,
): Promise<IPlaceType[] | undefined | null> => {
  try {
    const tempData = await getList(EEndpoint.PLACETYPE);
    // eslint-disable-next-line no-use-before-define
    const tree = await putListTreeTogether(tempData.list as unknown as IPlaceType[], {
      currentFormId,
    });
    return Promise.resolve(tree);
  } catch (e) {
    console.log('ERROR', e);
    return Promise.resolve([]);
  }
};
export const getPlaceThemeForPlaceForm = async (): Promise<IPlaceType[] | undefined | null> => {
  try {
    const tempData = await getList(EEndpoint.PLACETYPE, { published: true });
    // eslint-disable-next-line no-use-before-define
    const tree = await putListTreeTogether(tempData.list as unknown as IPlaceType[], {
      routeLang: 'est',
      returnType: 'theme',
    });
    return Promise.resolve(tree);
  } catch (e) {
    console.log('ERROR', e);
    return Promise.resolve([]);
  }
};
export const getPlaceTypeForPlaceForm = async (): Promise<IPlaceType[] | undefined | null> => {
  try {
    const tempData = await getList(EEndpoint.PLACETYPE, { published: true });
    // eslint-disable-next-line no-use-before-define
    const tree = await putListTreeTogether(tempData.list as unknown as IPlaceType[], {
      routeLang: 'est',
      returnType: 'type',
    });
    return Promise.resolve(tree);
  } catch (e) {
    console.log('ERROR', e);
    return Promise.resolve([]);
  }
};
export const getResourceTree = async (routeLang: string): Promise<TCategoryResourceTreeArray> => {
  try {
    let lang = routeLang;
    if (!routeLang) {
      lang = 'est';
    }
    const categoryStore = useCategory();
    const tempData = await getResources();
    const tree = await mapTree(tempData, lang);
    categoryStore.setAdminItemsMap(itemsMap);
    categoryStore.setAdminFlatList(tempData);
    return Promise.resolve(tree);
  } catch (e) {
    console.log('ERROR', e);
    return Promise.resolve([]);
  }
};

export const getCategoryHashmap = async (): Promise<TCategoryResourceTreeHashmap> => {
  await getCategoryTree('eng');
  return Promise.resolve(itemsMap);
};

export const putTreeTogether = async (
  routeLang: string,
  params: IListParams = {},
  filter = false,
  category = true,
): Promise<TCategoryResourceTreeArray> => {
  let tempData: TCategoryResourceTreeArray;
  const categoryStore = useCategory();
  if (category) {
    if (categoryStore.getCategoryFlatList.length) {
      tempData = categoryStore.getCategoryFlatList;
    } else {
      await getCategoryTree(routeLang, params, filter);
      tempData = categoryStore.getCategoryFlatList;
    }
  } else if (categoryStore.getAdminFlatList.length) {
    tempData = categoryStore.getAdminFlatList;
  } else {
    tempData = await getResourceTree(routeLang);
  }

  const tree = await mapTree(tempData, routeLang, filter);
  return Promise.resolve(tree);
};

/* Returns array of ancestors */
// eslint-disable-next-line max-len
export const getAncestors = async (
  childId: number,
  lang: string,
  category = true,
): Promise<TCategoryResourceTree[]> => {
  const categoryStore = useCategory();
  let itemsMapStore: TCategoryResourceTreeHashmap = {};
  if (category) {
    if (Object.keys(categoryStore.getCategoryItemsMap).length) {
      itemsMapStore = categoryStore.getCategoryItemsMap;
    } else {
      await getCategoryTree(lang, {});
      itemsMapStore = categoryStore.getCategoryItemsMap;
    }
  } else if (!category) {
    if (Object.keys(categoryStore.getAdminItemsMap).length) {
      itemsMapStore = categoryStore.getAdminItemsMap;
    } else {
      await getResourceTree(lang);
      itemsMapStore = categoryStore.getAdminItemsMap;
    }
  }

  const ancestors: TCategoryResourceTree[] = [];
  const findAncestors = (id: number) => {
    const item = itemsMapStore[id];
    if (item) {
      ancestors.push(item);
      findAncestors(item.parent);
    }
  };
  findAncestors(childId);
  return Promise.resolve(ancestors.reverse());
};

// eslint-disable-next-line max-len
export const fetchCategoryWithChildrenTree = async (
  parentId: number,
  lang: string,
): Promise<ICategory | undefined> => {
  const categoryStore = useCategory();
  let itemsMapStore: TCategoryResourceTreeHashmap = {};

  if (Object.keys(categoryStore.getCategoryItemsMap).length) {
    itemsMapStore = categoryStore.getCategoryItemsMap;
  } else {
    await getCategoryTree(lang, {});
    itemsMapStore = categoryStore.getCategoryItemsMap;
  }
  const categories: any = [];
  const parentCategory = { ...itemsMapStore[parentId], children: [] };
  // push parent to categoryList
  categories.push(parentCategory);

  const findChildren = async (id: number) => {
    Object.values(itemsMapStore).forEach(async (item) => {
      if (item && item.parent === id) {
        const child = { ...item };
        categories.push(child);
        findChildren(item.id);
      }
    });
  };
  await findChildren(parentId);

  const idMapping = categories.reduce((acc: any, el: any, i: any) => {
    acc[el.id] = i;
    return acc;
  }, {});

  let categoryWithChildrenTree: ICategory | undefined;
  categories.forEach((el: any) => {
    if (el.id === parentId) {
      categoryWithChildrenTree = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = categories[idMapping[el.parent]];
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el];
  });
  if (categoryWithChildrenTree) {
    return Promise.resolve(categoryWithChildrenTree);
  }
  return Promise.reject(categoryWithChildrenTree);
};

export const hashMap = ref<Map<number, Record<number, TPlaceTypeTree>>>(new Map());
const itemsTree = ref<TPlaceTypeTreeArray>();
const placeTheme = ref<TPlaceTypeTreeArray>([]);
// Prevent infinite loop
const processedMapTreeRecIds = ref<number[]>([]);
// eslint-disable-next-line max-len
export const mapTreeRec = (items: IPlaceType[]): TPlaceTypeTreeArray => items
  .filter((item) => !processedMapTreeRecIds.value.includes(item.id))
  .map((item: IPlaceType) => {
    const hashMapItem = hashMap.value?.get(item.id);
    if (hashMapItem) {
      processedMapTreeRecIds.value.push(item.id);
      // eslint-disable-next-line no-param-reassign
      item.children = Object.values(hashMapItem);
      // item.key = item.id;
      if (item.children.length) {
        // eslint-disable-next-line no-param-reassign
        item.children = mapTreeRec(item.children);
      }
    }
    return item;
  });

export const clearProcessedMapTreeRecIds = () => {
  processedMapTreeRecIds.value = [];
};

// eslint-disable-next-line max-len
const placeTypeForPlaceForm = (items: TPlaceTypeTreeArray | undefined) => items?.map((item: IPlaceType) => {
  if (item.children && item.children.length) {
    // eslint-disable-next-line no-param-reassign
    item.children = item.children.filter((e) => !placeTheme.value.includes(e));
    // eslint-disable-next-line no-param-reassign
    item.disabled = true;
    placeTypeForPlaceForm(item.children);
  }

  return item;
});

interface IPutListTreeTogetherOptions {
  routeLang?: string;
  returnType?: string;
  addId?: boolean;
  currentFormId?: number;
}

export const putListTreeTogether = async (
  items: IPlaceType[],
  {
    routeLang = 'est',
    returnType = 'regular',
    addId = false,
    currentFormId,
  }: IPutListTreeTogetherOptions = {},
) => {
  hashMap.value = new Map();
  console.time('hashmapCreation');
  placeTheme.value = [];
  items.forEach((item: IPlaceType) => {
    const { parentId } = item;
    // eslint-disable-next-line no-param-reassign
    item.label = `${getLanguageString(item.translations, routeLang || 'est', 'name')}${
      addId ? ` [${item.id}]` : ''
    }`;
    if (item.id === currentFormId) {
      // eslint-disable-next-line no-param-reassign
      item.disabled = true;
    }
    if (item.restrictiveFilter && returnType !== 'regular') {
      placeTheme.value.push(item);
    }

    if (!hashMap.value.has(parentId)) {
      hashMap.value.set(parentId, { [item.sort]: item });
    } else {
      const sortedChildrens = hashMap.value.get(parentId);
      if (sortedChildrens) {
        sortedChildrens[item.sort] = item;
        hashMap.value.set(parentId, sortedChildrens);
      }
    }
  });
  const parentValues = hashMap.value.get(0);
  if (parentValues) {
    itemsTree.value = mapTreeRec(Object.values(parentValues));
    clearProcessedMapTreeRecIds();
  }
  console.timeEnd('hashmapCreation');
  if (returnType === 'theme') {
    placeTheme.value.map((e: IPlaceType) => {
      e.disabled = true;
      return e;
    });
    return placeTheme.value;
  }
  if (returnType === 'type') {
    return placeTypeForPlaceForm(itemsTree.value);
  }

  return itemsTree.value;
};

// eslint-disable-next-line max-len
export const getRestrictedServiceTypesTree = async (
  routeLang: string,
): Promise<TCategoryResourceTreeArray> => {
  try {
    const { list } = await getList(EEndpoint.SERVICETYPE, { published: true });
    const tree = await putListTreeTogether(list as unknown as IPlaceType[], {
      routeLang,
      returnType: 'type',
    });
    return Promise.resolve(tree as unknown as TCategoryResourceTreeArray);
  } catch (e) {
    console.error('ERROR: ', e);
    return Promise.resolve([]);
  }
};

// eslint-disable-next-line max-len
export async function mapRestrictedLangTree(
  items: TCategoryResourceTreeArray,
  routeLang: string,
  addId = false,
): Promise<TCategoryResourceTreeArray> {
  const languageStore = useLanguage();
  let tree: TCategoryResourceTreeArray = [];
  const mappedArr: TCategoryResourceTreeHashmap = {};
  const activeLangId = languageStore.getLanguageId(routeLang);
  items.forEach((item: TCategoryResourceTree) => {
    const { id } = item;
    const { name, published } = getCategoryNaming(item, routeLang);
    const disabled = item.categoryObjects.every((object) => object.languageId !== activeLangId);
    if (!mappedArr[id]) {
      mappedArr[id] = {
        ...item,
        label: `${name}${addId ? ` [${id}]` : ''}`,
        published,
        categoryObjectId: item.id,
      };
    }
    if (disabled) {
      mappedArr[id].disabled = true;
    }
  });
  Object.keys(mappedArr).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(mappedArr, key)) {
      const mappedElem = mappedArr[key];
      if (Object.prototype.hasOwnProperty.call(mappedArr, mappedElem.parent)) {
        const parentId = mappedElem.parent;
        if (!mappedArr[parentId].children) {
          mappedArr[parentId].children = [];
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedArr[parentId].children.push(mappedElem);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tree.push(mappedElem);
      }
    }
  });

  tree = tree.sort((a: TCategoryResourceTree, b: TCategoryResourceTree) => {
    if (a.sort > b.sort) {
      return 1;
    }
    if (a.sort < b.sort) {
      return -1;
    }

    return 0;
  });
  return tree;
}
