import { useRoute } from 'vue-router';
import { IFileResponse } from '@/interfaces/file';
import { IDocument } from '../interfaces/document';
import api from './api';

export const addDocument = async (file: IFileResponse): Promise<IDocument> => {
  try {
    const route = useRoute();
    const { id: fileId, originalName, createdAt } = file;
    const documentData = {
      fileId,
      translations: [
        {
          lang: route?.params?.lang || 'est',
          name: `(${originalName})`,
        },
      ],
      date: (createdAt as unknown as string).split('T')[0],
    };
    const { data } = await api.post('admin/media/document', documentData);
    return Promise.resolve(data);
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};
