import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
import { AuthRefreshRequestBody, AuthResponseBody, IAuth } from '@/interfaces/auth';
import api from '@/services/api';
import { handleError } from '@/utils/handleError';

export const auth = async (user: IAuth): Promise<AuthResponseBody> => {
  try {
    const resp = await api.post('auth', user);
    return Promise.resolve(resp.data);
  } catch (e) {
    return Promise.reject(handleError(e));
  }
};

export const refreshTokens = async (): Promise<AxiosResponse<AuthResponseBody>> => {
  const refreshToken = Cookies.get('refreshToken') || '';

  const data: AuthRefreshRequestBody = {
    refreshToken,
  };

  return api.post<AuthResponseBody>('auth/renew', data);
};
