enum ERelations {
    CONTACT_RELATED_ARTICLES = 'contact_related_articles',
    CONTACT_MAILGROUPS = 'contact_mailboxes',
    ARTICLE_RELATIONS = 'article_relations',
    CATEGORY_RELATIONS = 'category_relations',
    ARTICLE_SYMLINKS = 'article_symlinks',
    MEDIA_RELATIONS = 'media_relations',
    DMC_SERVICE_PLACES = 'dmc_service_places',
    COUNTRY_RELATIONS = 'country_relations'
}

export default ERelations;
