import { computed, Ref, unref } from 'vue';
import { LocationQueryRaw } from 'vue-router';

export function usePagination(
  dataCount:Ref<number>,
  routeQuery:Ref<LocationQueryRaw>,
  items = 100,
) {
  const ITEMS_PER_PAGE = unref(items);
  const showPagination = computed(() => dataCount.value > ITEMS_PER_PAGE);

  const paginationPageCount = computed(() => Math.ceil(dataCount.value / ITEMS_PER_PAGE));

  const paginationPageSlot = computed(() => {
    if (dataCount.value > (ITEMS_PER_PAGE * 9)) {
      return 7;
    }
    return 9;
  });

  const pageNumber = computed(() => {
    if ('offset' in routeQuery.value) {
      const offset = parseInt(routeQuery.value.offset as string, 10);
      if (offset === 0) {
        return 1;
      }

      return Math.floor((offset / ITEMS_PER_PAGE) + 1);
    }
    return 1;
  });

  const offset = (page:number) => (page - 1) * ITEMS_PER_PAGE;

  return {
    showPagination,
    paginationPageCount,
    paginationPageSlot,
    pageNumber,
    offset,
  };
}
