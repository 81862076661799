enum ESchemaItemType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  CONDITIONALCHECKBOX = 'conditionalcheckbox',
  TOGGLE = 'switch',
  TOGGLE_WITH_CONFIRM = 'switch-with-poconfirm',
  STATIC = 'static',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  TREESELECT = 'treeselect',
  TREESELECTCHECK = 'treeslectcheck',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  FILTERABLESELECT = 'filterableselect',
  LINK = 'link',
  URL = 'url',
  SITE_URL = 'siteUrl',
  RIGHTS = 'rights',
  EMAIL = 'email',
  PHONE = 'phone',
  DATE = 'date',
  DATERANGE = 'daterange',
  PASSWORD = 'password',
  UPLOAD = 'upload',
  IMAGE = 'image',
  DOCUMENT = 'document',
  DOCUMENTSELECT = 'documentselect',
  EDITOR = 'editor',
  INADS = 'inads',
  EMBEDDED = 'embedded',
  EMBEDDED_MODULE = 'module',
  TIMEPICKER = 'timepicker',
  TIMETABLE = 'timetable',
  MEDIASELECT = 'mediaselect',
  RADIO = 'radio',
  PARTNERSLUG = 'partnerslug'
}

export enum ELanguageTabType {
  CMS = 'cmsLanguage',
}

export default ESchemaItemType;
