import { createApp } from 'vue';

import {
  create,
  NButton,
  NCheckbox,
  NCheckboxGroup,
  NCollapseTransition,
  NDataTable,
  NDrawer,
  NDrawerContent,
  NEllipsis,
  NForm,
  NFormItem,
  NIcon,
  NInput,
  NInputNumber,
  NLayout,
  NLayoutContent,
  NLayoutHeader,
  NLayoutSider,
  NModal,
  NNotificationProvider,
  NLoadingBarProvider,
  NDialogProvider,
  NPopconfirm,
  NPopover,
  NSelect,
  NSpace,
  NSwitch,
  NTab,
  NTable,
  NTabPane,
  NTabs,
  NTooltip,
  NTreeSelect,
  NMenu,
  NDatePicker,
  NGrid,
  NGi,
  NUpload,
  NRadioGroup,
  NRadio,
  NRadioButton,
  NCard,
  NEl,
  NPagination,
  NSpin,
  NTimePicker,
  NResult,
  NTree,
  NAlert,
  NEmpty,
  NCollapse,
  NCollapseItem,
  NTag,
} from 'naive-ui';
import EPrivilege from '@/enums/user';
import { createPinia } from 'pinia';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import router from './router';
import loadFonts from './plugins/webfontloader';
import editor from './plugins/editor';
import './styles/main.scss';

loadFonts();
editor();

const naive = create({
  components: [
    NButton,
    NSpace,
    NForm,
    NFormItem,
    NInput,
    NDrawer,
    NDrawerContent,
    NLayout,
    NLayoutSider,
    NLayoutHeader,
    NLayoutContent,
    NIcon,
    NTable,
    NDataTable,
    NCheckbox,
    NCheckboxGroup,
    NTabs,
    NTabPane,
    NTab,
    NSwitch,
    NTooltip,
    NInputNumber,
    NPopconfirm,
    NModal,
    NPopover,
    NSelect,
    NCollapseTransition,
    NTreeSelect,
    NEllipsis,
    NNotificationProvider,
    NLoadingBarProvider,
    NDialogProvider,
    NDatePicker,
    NMenu,
    NGrid,
    NGi,
    NUpload,
    NRadioGroup,
    NRadio,
    NRadioButton,
    NCard,
    NEl,
    NPagination,
    NSpin,
    NTimePicker,
    NResult,
    NTree,
    NAlert,
    NEmpty,
    NCollapse,
    NCollapseItem,
    NTag,
  ],
});

const app = createApp(App);
app.use(i18n)
  .use(createPinia())
  .use(naive)
  .use(router)
  .mount('#app');

app.directive('privilege', (el, { value }) => {
  // TODO: Get real user privileges
  const dummyUserPrivileges: EPrivilege[] = [EPrivilege.SPECIAL];

  if (!dummyUserPrivileges.find((priv) => priv === value)) {
    el.parentElement.removeChild(el);
  }
});
