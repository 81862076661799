import ESchemaItemType from '@/enums/form';
import i18n from '@/plugins/i18n';
import { IFormData, ISchemaNode } from '@/interfaces/form';
import EFilterFields from '@/enums/filter';
import textLabelTypes from '@/modulesConf/textLabel/textLabelTypes';

const { t } = i18n.global;
let localFormData: IFormData = {};

const formatDateRange = (value:string[]|undefined) => {
  localFormData.tmp_daterange = value;
  if (!localFormData) {
    return;
  }
  if (!value || value.length < 2) {
    localFormData.dateFrom = undefined;
    localFormData.dateTo = undefined;
    return;
  }

  const [start, end] = value;
  if (start && end) {
    localFormData.dateFrom = start;
    localFormData.dateTo = end;
  }
};

const model = {
  [EFilterFields.search]: {
    searchCombo: '',
  },
  [EFilterFields.waitingPublished]: {
    waitingPublished: 'tmp_all',
  },
  [EFilterFields.published]: {
    published: 'tmp_all',
  },
  [EFilterFields.labelType]: {
    type: 'LABEL',
  },
  [EFilterFields.isAdmin]: {
    isAdmin: 'tmp_all',
  },
  [EFilterFields.active]: {
    active: '1',
  },
  [EFilterFields.dateRange]: {
    tmp_daterange: null,
    dateFrom: undefined,
    dateTo: undefined,
  },
};
const filterFields = (key:EFilterFields) => {
  const schema:{ [key in EFilterFields]: ISchemaNode} = {
    [EFilterFields.search]: {
      item: {
        dataId: 'searchCombo',
        type: ESchemaItemType.TEXT,
        label: t('filter_search_label'),
        rules: [],
        itemProps: {
          placeholder: t('filter_search_placeholder'),
          clearable: true,
        },
      },
    },
    [EFilterFields.waitingPublished]: {
      item: {
        dataId: 'waitingPublished',
        type: ESchemaItemType.SELECT,
        label: t('filter_waitingPublished'),
        rules: [],
        options: [
          {
            label: t('filter_optionAll'),
            value: 'tmp_all',
          },
          {
            label: t('filter_published_true'),
            value: true,
          },
          {
            label: t('filter_published_false'),
            value: false,
          },
        ],
      },
    },
    [EFilterFields.published]: {
      item: {
        dataId: 'published',
        type: ESchemaItemType.SELECT,
        options: [
          {
            label: t('filter_optionAll'),
            value: 'tmp_all',
          },
          {
            label: t('filter_published_true'),
            value: true,
          },
          {
            label: t('filter_published_false'),
            value: false,
          },
        ],
        label: t('filter_published_label'),
        rules: [],
      },
    },
    [EFilterFields.isAdmin]: {
      item: {
        dataId: 'isAdmin',
        type: ESchemaItemType.SELECT,
        options: [
          {
            label: t('filter_optionAll'),
            value: 'tmp_all',
          },
          {
            label: t('filter_boolean_true'),
            value: true,
          },
          {
            label: t('filter_boolean_false'),
            value: false,
          },
        ],
        label: t('filter_isAdminAll'),
        rules: [],
      },
    },
    [EFilterFields.labelType]: {
      item: {
        dataId: 'type',
        type: ESchemaItemType.SELECT,
        options: [
          {
            label: t('filter_optionAll'),
            value: 'tmp_all',
          },
          ...textLabelTypes,
        ],
        label: t('filter_labelType_type'),
        rules: [],
      },
    },
    [EFilterFields.active]: {
      item: {
        dataId: 'active',
        type: ESchemaItemType.SELECT,
        options: [
          {
            label: t('filter_optionAll'),
            value: 'tmp_all',
          },
          {
            label: t('filter_active_true'),
            value: '1',
          },
          {
            label: t('filter_active_false'),
            value: '0',
          },
        ],
        label: t('filter_active_label'),
        rules: [],
      },
    },
    [EFilterFields.dateRange]: {
      item: {
        dataId: 'tmp_daterange',
        type: ESchemaItemType.DATERANGE,
        label: t('filter_dateRange'),
        rules: [],
        itemProps: {
          type: 'daterange',
          'close-on-select': true,
          'update-value-on-close': true,
        },
        events: {
          onUpdate: (value) => {
            if (value === undefined) {
              formatDateRange(value);
            }

            formatDateRange(value as unknown as string[]);
          },

        },
      },
    },
  };

  return schema[key];
};

export const useFormData = (formData: IFormData) => {
  localFormData = formData;
};

export const commonFilterModel = (filters:EFilterFields[]):IFormData => filters
  .reduce((acc, filter) => ({
    ...acc,
    ...model[filter] as IFormData,
  }), {} as IFormData);

export const cleanFilterObject = (filterOptions: IFormData) => {
  const cleanModel = { ...filterOptions };
  Object.keys(cleanModel).forEach((key) => {
    if (cleanModel[key] === '' || cleanModel[key] === 'tmp_all' || key.startsWith('tmp_') || key === 'returnQuery') {
      delete cleanModel[key];
    }
  });
  return cleanModel;
};

export default filterFields;
