import { FormItemRule } from 'naive-ui/lib/form/src/interface';
import ERules from '@/enums/validationRules';
import ESchemaItemType from '@/enums/form';
import TRuleValue from '@/types/ruleTypes';
import isValidEmailFormat from '@/utils/validateEmail';
import i18n from '@/plugins/i18n';
import { SLUG } from '@admin-shared/constants/regex.constant';

const { t } = i18n.global;
/**
 * Add type based default rules
 * @param type
 */
export const typeRules = (type: ESchemaItemType):FormItemRule => {
  const rule: FormItemRule = {};

  if (type === ESchemaItemType.TOGGLE) {
    rule.type = 'boolean';
  }
  if (type === ESchemaItemType.INTEGER) {
    rule.type = 'integer';
    rule.message = t('validation.integer');
  }
  if (type === ESchemaItemType.EMAIL) {
    rule.validator = (r: FormItemRule, value: string) => {
      if (value?.length) {
        return isValidEmailFormat(value);
      }
      return true;
    };
    rule.message = t('validation.email');
  }
  if (type === ESchemaItemType.PARTNERSLUG) {
    rule.validator = (r: FormItemRule, value: string) => {
      if (value?.length) {
        return SLUG.test(value);
      }
      return true;
    };
    rule.message = t('tallinncard_slug_validation');
  }
  if (type === ESchemaItemType.PHONE) {
    rule.validator = (r: FormItemRule, value: string) => {
      if (value && value.length) {
        return /^\+?\d+([ -]?\d)+$/i.test(value);
      }
      return true;
    };
    rule.message = t('validation.phone');
  }
  if (type === ESchemaItemType.URL) {
    rule.validator = (r: FormItemRule, value: string) => {
      const urlPattern = new RegExp('^\\s*' // there could be some whitespaces
        + '(https?:\\/\\/)?' // validate protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
        + '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
      if (value && value.length) {
        return !!urlPattern.test(value);
      }
      return true;
    };
    rule.message = t('validation_link');
  }
  if (type === ESchemaItemType.SITE_URL) {
    rule.validator = (r: FormItemRule, value: string) => {
      const urlPattern = /^\s*(https?:\/\/?)|^((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,})/i;
      if (value && value.length) {
        return !urlPattern.test(value);
      }
      return true;
    };
    rule.message = t('validation_slug');
  }

  return rule;
};

export const getRule = (ruleName:ERules, value: TRuleValue = ''): FormItemRule => {
  switch (ruleName) {
    case ERules.required:
      return {
        required: true,
        message: t('validation_required'),
      };
    case ERules.maxLength:
      return {
        required: true,
        message: t('validation_maxLength', { value }),
      };

    default:
      return {};
  }
};
