// FROM: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

const formatBytes = (bytes:number|string, decimals = 2) => {
  const by = parseInt(bytes as string, 10);
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(by) / Math.log(k));

  return `${parseFloat((by / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
