import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import CategoryContent from '@/views/CategoryContent.vue';
import Layout from '@/layout/BaseLayout.vue';
import List from '@/views/List.vue';
import Form from '@/views/Form.vue';
import Login from '@/components/Auth/Login.vue';
import { maybeUpdateLang } from '@/utils/router';
import useAuthStore from '@/store/auth';

const routes: RouteRecordRaw[] = [
  {
    path: '/:locale?',
    component: Layout,
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: 'logout',
        name: 'logout',
        redirect: '/login',
      },
      {
        path: 'categories/:lang/:cId?/:activeTab?',
        name: 'categories',
        component: CategoryContent,
      },
      {
        path: 'resources/:lang',
        name: 'resources',
        component: Home,
      },
      {
        path: 'categories',
        redirect: '/categories/eng',
      },
      {
        path: 'resources',
        redirect: '/resources/eng',
      },
      {
        path: 'categories/:lang/:cId?/:id',
        name: 'edit',
        component: Form,
      },
      {
        path: 'demo/:lang',
        name: 'demo',
        component: () => import('@/views/FormDemo.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: 'list/:module/:lang?',
        name: 'module',
        component: List,
      },
      {
        path: 'form/:module/:lang?/:cId?/:id',
        name: 'edit',
        component: Form,
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
      },
    ],
  },

  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: () => import('@/views/Result.vue'),
    props: {
      status: '404',
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore();
  let isValid = false;
  try {
    isValid = await authStore.isValid();
  } catch (e) {
    console.log(e);
  }

  // check token
  if (to.name !== 'login' && !isValid) {
    return { name: 'login' };
  }

  if (
    to.matched.length
    && !to.matched.some((match) => match.name === 'notFound')
  ) {
    const nextResp = await maybeUpdateLang(to, from);
    if (nextResp) {
      return nextResp;
    }
  }
  return true;
});

export default router;
