import ELabelType from '@/enums/labelType';
import i18n from '@/plugins/i18n';

const { t } = i18n.global;

const types = [
  {
    label: () => t('filter_labelType_label'),
    value: ELabelType.LABEL,
  },
  {
    label: () => t('filter_labelType_command'),
    value: ELabelType.COMMAND,
  },
  {
    label: () => t('filter_labelType_error'),
    value: ELabelType.ERROR,
  },
  {
    label: () => t('filter_labelType_message'),
    value: ELabelType.MESSAGE,
  },
];

export default types;
