import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { IAuth } from '@/interfaces/auth';
import { auth, refreshTokens } from '@/services/auth';
import { handleError } from '@/utils/handleError';
import useUserStore from '@/store/useUser';
import api from '@/services/api';
import router from '@/router';
import { parseJwt } from '@/utils/parseJwt';

const setTokenCookies = (accessToken: string, refreshToken: string) => {
  Cookies.set('token', accessToken, { secure: true });
  Cookies.set('refreshToken', refreshToken, { secure: true });
};

const useAuth = defineStore('auth', {
  state: () => ({
    tokenExp: 0,
  }),
  actions: {
    async auth(user: IAuth) {
      const userStore = useUserStore();
      try {
        const { accessToken, refreshToken } = await auth(user);
        this.tokenExp = parseJwt(accessToken).exp;
        setTokenCookies(accessToken, refreshToken);
        await userStore.getUserInfo();
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(handleError(e));
      }
    },
    async renewToken() {
      const userStore = useUserStore();
      let response;
      try {
        response = await refreshTokens();
      } catch (e) {
        return Promise.reject();
      }
      try {
        const { accessToken, refreshToken } = response.data;
        setTokenCookies(accessToken, refreshToken);
        await userStore.getUserInfo();
        this.tokenExp = parseJwt(accessToken).exp;
        return Promise.resolve(response);
      } catch (e) {
        handleError(e);
        this.tokenExp = 0;
        return Promise.reject(e);
      }
    },
    async logout() {
      const userStore = useUserStore();
      const refreshToken = Cookies.get('refreshToken');
      if (refreshToken) {
        await api.post('auth/logout', { refreshToken });
      }
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      this.tokenExp = 0;
      userStore.clearUserInfo();
      await router.push({ name: 'logout' });
    },
    async isValid() {
      const now = new Date(Date.now());
      // eslint-disable-next-line max-len
      // console.log('valid??', this.tokenExp, new Date(this.tokenExp * 1000) > now, new Date(this.tokenExp * 1000), now);
      if (new Date(this.tokenExp * 1000) > now) {
        return true;
      }
      try {
        const { status: renewStatus } = await this.renewToken();
        return renewStatus === 201;
      } catch (e) {
        handleError(e);
        return false;
      }
    },
  },
});

export default useAuth;
