enum EFilterFields {
  search = 'search',
  published = 'published',
  labelType = 'labelType',
  isAdmin = 'isAdmin',
  active = 'active',
  waitingPublished = 'waitingPublished',
  dateRange = 'dateRange'
}

export default EFilterFields;
