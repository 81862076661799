import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { IError } from '@/interfaces/error';

type TErrorStore = IError | AxiosError | undefined;
const useError = defineStore('error', {
  state: () => ({
    error: undefined as TErrorStore,
  }),
  getters: {
    getError: (state) => state.error,
  },
  actions: {
    setError(error?: IError | AxiosError) {
      this.error = error;
    },
  },
});

export default useError;
