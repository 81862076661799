import { EFileType } from '@admin-shared/enums/files/file-type.enum';
import { IFormMeta } from '@/interfaces/formMeta';
import { IFileResponse } from '@/interfaces/file';
import { ICategory } from '@/interfaces/category';
import { IMediaLanguage } from '@/interfaces/image';
import { IDocumentCategory } from '@/interfaces/document';

export interface ICategoryMedia {
  id: number;
  categoryId?: number;
  categoryObjectId?: number;
  mediaId: number;
  sort: number;
}
export interface ISimpleMedia {
  mediaId: number;
  mediaType?: EFileType;
  type?: EFileType | Lowercase<EFileType>;
  media?: {
    mediaType?: EFileType | Lowercase<EFileType>;
  };
  sort?: number;
  publishedLangs: string[]
}
// MediaResponseDto
export interface IMediaDetail extends Partial<IFormMeta> {
  id: number | undefined;
  mediaType: EFileType | undefined;
  videoImageId: number | undefined;
  fileId: number | undefined;
  file: IFileResponse | undefined;
  mediaRightsId: number | undefined;
  author: string;
  date: Date | undefined;
  showOriginal: boolean | Record<string, never>;
  metainfo?: {
    [key: string]: string;
  };
  published: boolean;
  translations?: IMediaLanguage[];
  categoryMedia?: ICategoryMedia;
  categoryObjectMedia?: IDocumentCategory[];
  categories: ICategory[];
}
export enum EMediaDetailType {
  DOCUMENT = 'document',
  IMAGE = 'image',
}
export type TMediaDetailState = {
  [key in EMediaDetailType]: IMediaDetail;
};

export interface IMediaBase {
  mediaId: number;
  mediaType: EMediaDetailType;
}
