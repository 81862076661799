import axios, { AxiosResponse } from 'axios';
import { IList, ISort } from '@/interfaces/list';
import api from '@/services/api';
import { IFormData } from '@/interfaces/form';
import { IListParams } from '@/interfaces/api';
import { TFormData } from '@/types/formTypes';

// eslint-disable-next-line no-use-before-define
type TRemovablePropertyObject = { [key: string]: TRemovableProperty | TRemovableProperty[] }
type TRemovableProperty = string | TRemovablePropertyObject

// eslint-disable-next-line max-len
const cleanObject = (data: IFormData, excluded: TRemovableProperty[] = []): IFormData | TFormData => {
  // collect objects
  const excludedObjects = excluded.filter((item) => typeof item === 'object');
  // combine excludedObjets
  let excludedObject: TRemovablePropertyObject = {};
  (excludedObjects as TRemovablePropertyObject[]).forEach((object) => {
    excludedObject = { ...excludedObject, ...object };
  });
  // collect keys
  const excludedObjectsKeys = Object.keys(excludedObject);

  return Object.keys(data)
    .reduce((obj, key: string) => {
      const value = data[key];
      // remove attributes with tmp_ prefix && remove attributes listed in excluded array
      if (!/tmp_.+/[Symbol.match](key) && !excluded.includes(key)) {
        // excludedObjectsKeys check
        if (excludedObjectsKeys.includes(key)) {
          // in case of array, try to clean its items
          if (Array.isArray(value)) {
            const cleanedItems = value.map(
              (item) => {
                // todo: nested arrays not supported! [[{},{}],[{},{}]]
                // if (Array.isArray(item)) {}

                if (typeof item === 'object') {
                  return cleanObject(
                    item as IFormData,
                    excludedObject[key] as TRemovableProperty[],
                  ) as IFormData;
                }
                return item;
              }
              ,
            );
            return { ...obj, [key]: cleanedItems };
          }

          // else, it's an object! look inside
          const cleaned = cleanObject(
            value as IFormData,
            excludedObject[key] as TRemovableProperty[],
          ) as IFormData;
          return { ...obj, [key]: cleaned };
        }

        return { ...obj, [key]: value };
      }
      return obj;
    }, {});
};

export const getList = async <ReturnType = IList>(
  endpoint: string,
  params: IListParams = {},
): Promise<ReturnType> => api.get<ReturnType>(endpoint, { params })
  .then(({ data }: { data: ReturnType }) => data);

export const getItemById = async <ResponseType = IFormData>(endpoint: string, id: number)
  : Promise<ResponseType> => {
  let response;
  try {
    if (Number.isNaN(id)) {
      return Promise.reject();
    }
    response = await api.get<ResponseType>(`${endpoint}/${id}`);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return Promise.reject(e.response?.data);
    }
    return Promise.reject();
  }
};

export const getCsv = async (endpoint:string, filters:IListParams = {}) :Promise<unknown> => {
  try {
    const response = await api.get(`${endpoint}/csv`, { params: filters });
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return Promise.reject(e.response?.data);
    }
    return Promise.reject();
  }
};

export const deleteItemById = async (endpoint: string, id: number): Promise<AxiosResponse> => api.delete(`${endpoint}/${id}`);
export const forceDeleteItemById = async (endpoint: string, id: number): Promise<AxiosResponse> => api.delete(`${endpoint}/${id}/?force=true`);

export const switchItemPublishToggle = async (endpoint: string, id: number): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}/publish`);
export const switchItemUnPublishToggle = async (endpoint: string, id: number): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}/unpublish`);
export const publishAllItems = async (endpoint: string, id: number): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}/publish-all`);
export const unPublishAllItems = async (endpoint: string, id: number): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}/unpublish-all`);

export const updateItem = async <DataType = IFormData>(
  endpoint: string,
  id: number,
  data: DataType,
  removableProperty: TRemovableProperty[] = [],
): Promise<AxiosResponse> => api.put(
  `${endpoint}/${id}`,
  cleanObject(
    data as unknown as IFormData,
    [
      'updatedAt',
      'updatedBy',
      'updatedByAdminTxt',
      'createdBy',
      'createdByAdminTxt',
      'createdAt',
      'id',
      'sort',
      ...removableProperty,
    ],
  ),
);

export const postItem = async (
  endpoint: string,
  data: IFormData,
  removableProperty: TRemovableProperty[] = [],
): Promise<AxiosResponse> => api
  .post(endpoint, cleanObject(data, removableProperty));

export const massAction = async (endpoint: string, data: IFormData): Promise<AxiosResponse> => api.patch(`${endpoint}/multiple`, data);

export const forceMassAction = async (endpoint: string, data: IFormData): Promise<AxiosResponse> => api.patch(`${endpoint}/multiple/?force=true`, data);

export const patchSortSequence = async (endpoint: string, data: ISort): Promise<AxiosResponse> => api.patch(`${endpoint}/sort`, data);

export const patchMediaSortSequence = async (endpoint: string, id: number, data: ISort): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}`, data);

export const patchArticleReference = async (endpoint:string, id:number, body:IFormData): Promise<AxiosResponse> => api.patch(`${endpoint}/${id}/symlinks`, cleanObject(body));

export const postOpenForm = async (endpoint: string, id:number): Promise<AxiosResponse> => api.post(`${endpoint}/${id}/open-form`);

export const postCloseForm = async (endpoint: string, id:number): Promise<AxiosResponse> => api.post(`${endpoint}/${id}/close-form`);
