enum ERowActions {
  EDIT = 'edit',
  PUBLISH = 'publish',
  PUBLISHALL = 'publish_all',
  UNPUBLISHALL = 'unpublish_all',
  UNPUBLISH = 'unpublish',
  REFER = 'refel',
  DELETE = 'delete',
  HIDE = 'hide',
  ORDER = 'order',
  SELECT = 'select',
  DISABLE_FOR_USER = 'disable-for-user',
  PUBLISH_WITH_CONFIRM = 'publish-with-confirm',
  UNPUBLISH_WITH_CONFIRM = 'unpublish-with-confirm',
  REMOVE_FROM_MAILGROUP = 'remove_from_mailgroup',
  ADD_TO_MAILGROUP = 'add_to_mailgroup',
  VIEW = 'view',
  TC_GENERATE_CARD = 'tc_generate_card',
  TC_SEND_EMAIL = 'tc_send_email',
  TC_APP_SEND_EMAIL = 'tc_app_send_email'
}

export default ERowActions;
