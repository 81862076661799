import axios from 'axios';
import api from '@/services/api';
import { IFileResponse } from '@/interfaces/file';
import { IThumbnail } from '@/interfaces/IThumbnail';
import { EFileType } from '@admin-shared/enums/files/file-type.enum';

export const uploadFile = async (
  file:File,
  type:EFileType,
  onProgress:(e: { percent: number }) => void,
):Promise<IFileResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);

  try {
    const { data } = await api.post('files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: ({ loaded, total }) => {
        const percent = (loaded * 100) / total;
        onProgress({ percent: Math.ceil(percent) });
      },
    });
    return Promise.resolve(data);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return Promise.reject(e.response?.data);
    }
    return Promise.reject();
  }
};

export const updateThumbnail = async (thumbnail:IThumbnail, id:number) => {
  try {
    await api.put(`files/${id}/thumbnail/crop`, thumbnail);
    return Promise.resolve();
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};
