import { IFormMeta } from '@/interfaces/formMeta';
import { TCategoryResourceTree } from '@/types/categoryTypes';
import { TRelatedArticles } from './article';
import { ITranslations } from './placeType';

export interface ICategoryObject {
  id: number,
  name: string,
  description: string,
  urlAlias: string,
  link: string,
  iLink: string,
  published: boolean,
  languageId: number,
  categoryId: number,
  linkToNewwin: number,
  linkToCo: number,
  title: string,
  lang?: string,
  metaDescription: string | undefined,
  metaKeywords: string,
  useDefaultLanguageContent: boolean,
  hideHeadline: boolean,
  waitingPublished?: boolean,
}
export interface ICategory extends IFormMeta {
  id: number,
  sort: number,
  parent: number,
  admin: boolean,
  activated: boolean,
  isGroup: boolean,
  menuPosition: string,
  frontendComponent: string,
  objectType: string,
  showTallinncardShop: boolean,
  notFound: boolean,
  hideNotifications: boolean,
  showTallinncardBuyButton: boolean,
  label: string,
  key: number,
  published: boolean,
  categoryObjectId: number,
  children: ICategory[],
  categoryObjects: ICategoryObject[],
  disabled?: boolean,
  disableDirWrite?: boolean,
  imagesCount?: number,
  articlesCount?: {categoryObjectId: number}[]
  documentsCount?: {categoryObjectId: number}[]
}

export function isCategory(item: TCategoryResourceTree): item is ICategory {
  return (item as ICategory).menuPosition !== undefined;
}

export interface ICategoryHashmap {
  [key: string]: ICategory
}

export interface ICategoryPermissions {
  read?: boolean;
  write?: boolean;
  publish?: boolean;
  dirWrite?: boolean;
  dirPublish?: boolean;
}

export interface ICategoryRights extends ICategoryPermissions {
  id: number;
  userGroupId: number;
  categoryId: number;
}

export interface ICategoryRelatonItem {
  id: number,
  published: boolean,
  categoryId: 0,
  translations: ITranslations[],
}

export interface ICategoryRelations {
  categoryId?: number,
  SUBCATEGORY?: [
    {
      id: number,
      parent: number,
      categoryObject: ICategoryObject[],
    }
  ],
  ARTICLE_RELATION?: TRelatedArticles,
  PLACE_TYPE?: ICategoryRelatonItem[],
  EVENT_TYPE?: ICategoryRelatonItem[],
  SERVICE_TYPE?: ICategoryRelatonItem[],
}
