import { RouteLocationNormalizedLoaded } from 'vue-router';
import useLanguage from '@/store/language';
import { SUPPORTED_LOCALES } from '@/plugins/i18n';

interface ILanguageProps {
  lang?: string;
  languageId?: number;
}

export function getLanguageString<Type extends ILanguageProps, Key extends keyof Type>(
  translations: Type[],
  // eslint-disable-next-line default-param-last
  defaultLang = 'eng',
  fieldName: Key,
) {
  const languageStore = useLanguage();
  const firstLanguageId = languageStore.getLanguageId(defaultLang);

  // current language Category object
  const currentLanguage = translations.find(
    (translation) => translation.lang === defaultLang
      || (translation.languageId && translation.languageId === firstLanguageId),
  );

  if (currentLanguage) {
    return currentLanguage[fieldName];
  }

  // Replace language
  for (let l = 0; languageStore.languages.length > l; l += 1) {
    const lang = languageStore.languages[l];
    const translation = translations.find((t) => t.lang === lang?.brief);

    if (translation) {
      return `${translation[fieldName]}(${lang.brief})`;
    }
  }

  return '[Tõlgi]';
}
export function getLanguagePublishedValue<Type extends ILanguageProps, Key extends keyof Type>(
  translations: Type[],
  // eslint-disable-next-line default-param-last
  defaultLang = 'eng',
  fieldName: Key,
) {
  const languageStore = useLanguage();
  const firstLanguageId = languageStore.getLanguageId(defaultLang);

  // current language Category object
  const currentLanguage = translations.find(
    (translation) => translation.lang === defaultLang
      || (translation.languageId && translation.languageId === firstLanguageId),
  );

  if (currentLanguage) {
    return currentLanguage[fieldName];
  }

  // Replace language
  for (let l = 0; languageStore.languages.length > l; l += 1) {
    const lang = languageStore.languages[l];
    const translation = translations.find((t) => t.lang === lang?.brief);

    if (translation) {
      return translation[fieldName];
    }
  }

  return false;
}

export const getLanguageCode = (languageId: number | undefined) => {
  const languageStore = useLanguage();
  if (languageId) {
    const languageObject = languageStore.getLanguageById(languageId);
    return languageObject?.code;
  }
  return languageStore.allLanguages.find((o) => o.adminDefaultLanguage === true)?.code;
};

export const getLocale = (route: RouteLocationNormalizedLoaded): string => {
  const short = route?.params?.locale as string;
  const locale = SUPPORTED_LOCALES.find((local) => local.short === short);
  return locale?.long.toLowerCase() as string;
};

export const getLanguageCodeByBrief = (brief: string) => {
  const languageStore = useLanguage();
  return languageStore.getLanguage(brief)?.code || 'est';
};
