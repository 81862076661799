import { ref } from 'vue';
import { getCsv } from '@/services/listService';
import { IListParams } from '@/interfaces/api';

const fetchingCsv = ref(false);
const fetchCsv = async (apiUrl: string, query: IListParams) => {
  fetchingCsv.value = true;
  try {
    const csvDAta = await getCsv(apiUrl, query);

    const anchor = document.createElement('a');
    anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvDAta as string)}`;
    anchor.target = '_blank';
    anchor.download = 'export.csv';
    anchor.click();
    anchor.remove();
  } finally {
    fetchingCsv.value = false;
  }
};

export default function useCVExport() {
  return {
    fetchingCsv,
    fetchCsv,
  };
}
